import { useQuery } from "@apollo/client";
import { Sangha, SanghaType, Teacher } from "@app/shared/types";
import { Box, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_GROUPS_BY_TEACHER_ID, GRAPHQL_QUERY_TEACHER } from "app/queries";
import { theme } from "app/theme";
import classNames from "classnames";
import { AccentBar } from "components/AccentBar";
import DocumentContext from "components/DocumentContext";
import LoadingBar from "components/LoadingBar";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "features/navigation/NavLink";
import { useParams } from "react-router";
import InterestGroupCard from "../interestGroups/InterestGroupCard";

const useStyles = makeStyles((theme) => ({
    locationIcon: {
        fontSize: theme.typography.pxToRem(20),
    },
    teacherImage: {
        borderRadius: theme.borderRadius.default,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        objectFit: "cover",
        height: 300,
        width: 350,
        [theme.breakpoints.down("md")]: {
            height: 250,
            width: 300,
            margin: "0 auto",
        },
    },
    infoIcon: {
        fontSize: theme.typography.pxToRem(20),
        color: theme.palette.grey700,
    },
    infoText: {
        display: "flex",
        alignItems: "center",
        gap: 1,
        [theme.breakpoints.up("xs")]: {
            justifyContent: "center",
        },
        [theme.breakpoints.up("md")]: {
            justifyContent: "flex-start",
        },
    },
}));

export const TeacherPage = () => {
    const classes = useStyles();
    const { teacherId } = useParams<{ teacherId: string }>();
    const { data: teacherData, loading: teacherLoading } = useQuery(GRAPHQL_QUERY_TEACHER, {
        variables: {
            id: teacherId,
        },
    });

    const { data: sanghasData, loading: sanghasLoading } = useQuery(
        GRAPHQL_QUERY_GROUPS_BY_TEACHER_ID,
        {
            variables: {
                teacherId,
                sanghaType: SanghaType.InterestGroup,
            },
        },
    );

    if (teacherLoading || sanghasLoading) {
        return (
            <PageWrapper>
                <LoadingBar />
            </PageWrapper>
        );
    }

    const teacher = teacherData?.teacher as Teacher;
    const sanghas = sanghasData?.groupsByTeacherId as Sangha[];

    return (
        <div>
            <DocumentContext title={teacher.name} addBrandNameSuffix />
            <PageWrapper data-testId="teacherPage">
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        gap: {
                            xs: 2,
                            md: 8,
                        },
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                    }}
                >
                    <img
                        src={teacher?.picture || undefined}
                        alt={teacher?.name}
                        className={classes.teacherImage}
                    />

                    <Box sx={{ flex: 1 }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                                flexWrap: "wrap",
                            }}
                        >
                            <Typography variant="h1" sx={{ mb: { xs: 0 }, mr: { xs: 0, md: 2 } }}>
                                {teacher?.name}
                            </Typography>
                            <Typography variant="subtitle2" sx={{ color: theme.palette.grey700 }}>
                                {teacher?.pronouns}
                            </Typography>
                        </Box>
                        <Typography
                            variant="body1"
                            sx={{
                                color: theme.palette.grey700,
                                display: "flex",
                                alignItems: "center",
                                gap: 1,
                                justifyContent: {
                                    xs: "center",
                                    md: "left",
                                },
                            }}
                        >
                            <span
                                className={"material-symbols-rounded"}
                                style={{ fontSize: theme.typography.pxToRem(18) }}
                            >
                                location_on
                            </span>
                            <strong>{teacher?.location}</strong>
                        </Typography>
                        <Box
                            sx={{
                                display: "flex",
                                gap: { xs: 0, md: 8 },
                                flexDirection: {
                                    xs: "column",
                                    md: "row",
                                },
                            }}
                        >
                            <Typography variant="caption" className={classes.infoText}>
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.infoIcon,
                                    )}
                                >
                                    folded_hands
                                </span>
                                {teacher?.yearsMeditating} years meditating
                            </Typography>
                            <Typography variant="caption" className={classes.infoText}>
                                <span
                                    className={classNames(
                                        "material-symbols-rounded",
                                        classes.infoIcon,
                                    )}
                                >
                                    play_arrow
                                </span>
                                {teacher?.yearsTeaching} years teaching
                            </Typography>
                        </Box>
                        {!!teacher?.previewVideoLink &&
                            teacher.previewVideoLink.name !== "" &&
                            teacher.previewVideoLink.url !== "" && (
                                <NavLink
                                    to={teacher?.previewVideoLink?.url}
                                    style={{ textDecoration: "none" }}
                                    openInNewTab
                                >
                                    <Box
                                        sx={{
                                            width: "100%",
                                            borderRadius: `${theme.borderRadius.default}px`,
                                            backgroundColor: theme.palette.grey100,
                                            padding: 2,
                                            display: "flex",
                                            gap: 2,
                                            color: theme.palette.primaryLeaves,
                                            mt: 2,
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: "flex",
                                                backgroundColor: theme.palette.neutralCool,
                                                width: 80,
                                                height: 80,
                                                borderRadius: `${theme.borderRadius.small}px`,
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <span
                                                className={"material-symbols-rounded"}
                                                style={{
                                                    fontSize: theme.typography.pxToRem(40),
                                                    color: theme.palette.primaryLeaves,
                                                }}
                                            >
                                                play_arrow
                                            </span>
                                        </Box>

                                        <Box
                                            sx={{
                                                display: "flex",
                                                flexDirection: "column",
                                                width: "100%",
                                            }}
                                        >
                                            <Typography variant="subtitle1" sx={{ mb: 0 }}>
                                                Watch now
                                            </Typography>
                                            <Typography variant="body1" sx={{ mb: 0 }}>
                                                <strong>{teacher?.previewVideoLink?.name}</strong>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </NavLink>
                            )}
                    </Box>
                </Box>
                <AccentBar />
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: {
                            xs: "column",
                            md: "row",
                        },
                        gap: {
                            xs: 2,
                            md: 4,
                        },
                    }}
                >
                    <Box sx={{ whiteSpace: "pre-line" }}>
                        <Typography variant="h3">Bio</Typography>
                        <Typography variant="h6">About me</Typography>
                        <Typography variant="body1" sx={{ mb: 3 }}>
                            {teacher?.background}
                        </Typography>
                        <Typography variant="h6">I love working with</Typography>
                        <Typography variant="body1">{teacher?.loveWorkingWith}</Typography>
                        {!!teacher?.links && teacher.links.length > 0 && (
                            <>
                                <Typography variant="h6">Where you can find me</Typography>
                                <Typography variant="body1" sx={{ display: "flex", gap: 2 }}>
                                    {teacher?.links?.map((link) => (
                                        <NavLink to={link.url} key={link.name} openInNewTab>
                                            {link.name}
                                        </NavLink>
                                    ))}
                                </Typography>
                            </>
                        )}
                    </Box>
                    {!!teacher.certifications && teacher.certifications.length > 0 && (
                        <>
                            <Box
                                sx={{
                                    borderRadius: `${theme.borderRadius.default}px`,
                                    border: `1px solid ${theme.palette.grey300}`,
                                    padding: 4,
                                    width: {
                                        xs: "100%",
                                        md: 280,
                                    },
                                    height: "fit-content",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        color: theme.palette.primaryLeaves,
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 1,
                                        mb: 2,
                                    }}
                                >
                                    <span
                                        className={"material-symbols-rounded"}
                                        style={{
                                            fontSize: theme.typography.pxToRem(20),
                                        }}
                                    >
                                        school
                                    </span>
                                    Certifications
                                </Typography>
                                {teacher?.certifications?.map((certification) => (
                                    <>
                                        <Typography key={certification.name} variant="caption">
                                            <strong>{certification.name}</strong>
                                        </Typography>
                                        <Typography
                                            variant="caption"
                                            sx={{
                                                color: theme.palette.grey700,
                                                display: "block",
                                                mb: 2,
                                            }}
                                        >
                                            {certification.institution} -{" "}
                                            {certification.yearOfCompletion}
                                        </Typography>
                                    </>
                                ))}
                            </Box>
                        </>
                    )}
                </Box>
                {sanghas.length > 0 && (
                    <Box sx={{ mt: { xs: 4, md: 6 } }}>
                        <Typography variant="h3">Groups I am facilitating</Typography>
                        <Box
                            sx={{
                                marginTop: theme.spacing(1),
                                display: "grid",
                                gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
                                gap: "22px",
                                [theme.breakpoints.up("md")]: {
                                    gridTemplateColumns: "repeat(2, 1fr)",
                                },
                                [theme.breakpoints.up("lg")]: {
                                    gridTemplateColumns: "repeat(3, 1fr)",
                                },
                            }}
                        >
                            {sanghas?.map((sangha) => (
                                <InterestGroupCard key={sangha.id} interestGroup={sangha} />
                            ))}
                        </Box>
                    </Box>
                )}
            </PageWrapper>
        </div>
    );
};
