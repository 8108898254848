import { useMutation } from "@apollo/client";
import { Session } from "@app/shared/types";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_POST_CANCEL_SESSION_RSVP,
    GRAPHQL_MUTATION_POST_SESSION_RSVP,
    GRAPHQL_QUERY_EVENT_RSVP_USERS,
} from "app/queries";
import { routes } from "app/routes";
import classNames from "classnames";
import { CSDialog } from "components/CSDialog";
import { push } from "connected-react-router";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import _ from "lodash";
import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { UserProfile } from "react-redux-firebase";
import { useLocation } from "react-router";
import { getEventAnalyticsData } from "./AddToCalendarMenuItem";
import { AddToSingleCalendarButton, CalendarType } from "./AddToSingleCalendarButton";

const useStyles = makeStyles((theme) => ({
    root: { minHeight: "33px" },
    icon: {
        marginRight: theme.spacing(2),
    },
    rsvpModal: {
        minHeight: 300,
        textAlign: "center",
    },
}));

const calendarTypes: CalendarType[] = [
    CalendarType.apple,
    CalendarType.google,
    CalendarType.outlookcom,
    CalendarType.yahoo,
];

export const RsvpButton = (props: {
    event: Session;
    classes?: Partial<ReturnType<typeof useStyles>>;
    rsvpUsers?: UserProfile[];
    onAttend?: () => void;
    userRsvpedToSession: boolean;
}) => {
    const { event, onAttend, userRsvpedToSession } = props;
    const classes = useStyles(props);
    const [rvspModalOpen, setRsvpModalOpen] = useState(false);
    const user = useContext(LoggedInUserProfileContext);

    const dispatch = useDispatch();
    const location = useLocation();
    const currentPath = location.pathname;
    const isInOnboardingJourney = currentPath === routes.setupWelcomeSession();

    const [callPostSessionRsvp] = useMutation(GRAPHQL_MUTATION_POST_SESSION_RSVP, {
        refetchQueries: [{ query: GRAPHQL_QUERY_EVENT_RSVP_USERS, variables: { id: event.id } }],
    });

    const postSessionRsvp = async () => {
        await callPostSessionRsvp({
            variables: {
                sessionId: event.id,
                sendEmail: true,
            },
        });
    };

    const [callPostCancelSessionRsvp] = useMutation(GRAPHQL_MUTATION_POST_CANCEL_SESSION_RSVP, {
        refetchQueries: [{ query: GRAPHQL_QUERY_EVENT_RSVP_USERS, variables: { id: event.id } }],
    });

    const postCancelSessionRsvp = async () => {
        await callPostCancelSessionRsvp({
            variables: {
                sessionId: event.id,
            },
        });

        if (onAttend) {
            onAttend();
        }
    };

    const userIsAttending = () => {
        const rsvpForUser = _.find(props.rsvpUsers, {
            id: user.id,
        });

        return rsvpForUser;
    };

    const logEvent = (eventName: string, additionalData: Object = {}) => {
        analyticsTrack(eventName, {
            ...additionalData,
            ...getEventAnalyticsData(event),
        });
    };

    return (
        <div className={classes.root}>
            {userRsvpedToSession ? (
                <>
                    <Button
                        variant="primary"
                        size="small"
                        data-testid="rsvpCancelButton"
                        onClick={async () => {
                            await postCancelSessionRsvp();
                            logEvent("member.community.rsvp.cancel");
                        }}
                    >
                        Don't Attend
                    </Button>
                </>
            ) : (
                <Button
                    variant="primary"
                    size="small"
                    data-testid="rsvpButton"
                    onClick={async () => {
                        setRsvpModalOpen(true);
                        await postSessionRsvp();
                        logEvent("member.community.rsvp.click");
                    }}
                >
                    Attend
                </Button>
            )}
            <CSDialog
                onClose={() => {
                    if (onAttend) {
                        onAttend();
                    }
                    setRsvpModalOpen(false);
                    // if the user is in the setup/onboarding flow, we want to automatically redirect them after they rsvp'd to a welcome session
                    isInOnboardingJourney && dispatch(push(routes.setupShowYouAround()));
                }}
                open={rvspModalOpen}
            >
                <div data-testid="rsvpModal" className={classes.rsvpModal}>
                    <Typography variant="h4">Add this session to your calendar</Typography>
                    {calendarTypes.map((type) => (
                        <div key={type}>
                            <AddToSingleCalendarButton
                                calendarType={type}
                                event={event}
                                onClick={() => {
                                    logEvent("member.community.rsvp.add", {
                                        rsvpType: "calendar",
                                    });
                                    setRsvpModalOpen(false);
                                    // if the user is in the setup/onboarding flow, we want to automatically redirect them after they rsvp'd to a welcome session
                                    isInOnboardingJourney &&
                                        dispatch(push(routes.setupShowYouAround()));

                                    if (onAttend) {
                                        onAttend();
                                    }
                                }}
                            />
                        </div>
                    ))}
                </div>
            </CSDialog>
        </div>
    );
};
