import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_QUERY_MY_SCHEDULE, GRAPHQL_QUERY_UPCOMING_EVENTS } from "app/queries";
import { routes } from "app/routes";
import classNames from "classnames";
import { AnnouncementsCarousel } from "components/AnnouncementsCarousel";
import DocumentContext from "components/DocumentContext";
import PageWrapper from "components/PageWrapper";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LinkButton } from "features/navigation/LinkButton";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import { useContext, useEffect, useState } from "react";
import { EventListLoader } from "../eventDisplay/EventListLoader";
import {
    filterSessionsRightNow,
    sessionTypesForSessionsRightNow,
} from "./MemberSessionsRightNowPage";
import _ from "lodash";
import { OnboardingPopupButton } from "../onboarding/OnboardingPopupButton";
import { analyticsTrack } from "app/analytics/track";
import { ALL_ONBOARDING_ITEMS } from "@app/shared/constants";

const useStyles = makeStyles((theme) => ({
    pageWrapper: {
        marginTop: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
    },
    colorGrey800: {
        color: theme.palette.grey800,
    },
    myScheduleHeader: {
        color: theme.palette.grey800,
        marginTop: theme.spacing(2),
    },
    headerWithIconContainer: {
        display: "flex",
        alignItems: "center",
        gap: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1),
    },
    headerIcon: {
        color: theme.palette.warning500,
        fontSize: theme.typography.pxToRem(16),
    },
    emptyStateCard: {
        backgroundColor: theme.palette.grey100,
        borderRadius: theme.borderRadius.default,
        textAlign: "center",
        padding: theme.spacing(4, 7),
        [theme.breakpoints.up("sm")]: {
            width: "350px",
        },
        [theme.breakpoints.up("md")]: {
            width: "calc(100% / 2)",
        },
        [theme.breakpoints.up("lg")]: {
            width: "calc(100% / 3)",
        },
    },
    buttonContainer: {
        textAlign: "center",
    },
    noMargin: {
        margin: 0,
    },
}));

export const MemberHomePage = () => {
    const classes = useStyles();

    const user = useContext(LoggedInUserProfileContext);
    const userNickName = user.profile.nickName;

    const currentServerTime = useCurrentServerTime();

    const [showAllEvents, setShowAllEvents] = useState(false);

    const handleSeeAllEventsClick = () => {
        setShowAllEvents(true);
    };

    const seeMoreButton = () => (
        <Box className={classes.buttonContainer}>
            <Button variant="secondary" onClick={handleSeeAllEventsClick}>
                See all
            </Button>
        </Box>
    );

    const [showOnboardingPopup, setShowOnboardingPopup] = useState(false);

    const completedItemsFromDatabase = [...(user.profile.completedOnboardingItems || [])];

    const hasCompletedOnboarding = _.isEqual(
        completedItemsFromDatabase.sort(),
        ALL_ONBOARDING_ITEMS.sort(),
    );

    useEffect(() => {
        if (!hasCompletedOnboarding) {
            setShowOnboardingPopup(true);
        } else {
            analyticsTrack("onboarding.completed", {
                userId: user.id,
                onboardingItems: user.profile.completedOnboardingItems,
            });
        }
    }, [hasCompletedOnboarding]);

    return (
        <>
            <PageWrapper data-testid="memberHomePage" className={classes.pageWrapper}>
                <DocumentContext addBrandNameSuffix title="Home" />
                {showOnboardingPopup && <OnboardingPopupButton />}
                <Typography
                    variant="h2"
                    className={classNames(classes.noMargin, classes.colorGrey800)}
                >
                    Welcome back, {userNickName}
                </Typography>
                <EventListLoader
                    elementBefore={
                        <Typography
                            sx={{ mt: 5 }}
                            className={classes.myScheduleHeader}
                            variant="h3"
                        >
                            My Schedule
                        </Typography>
                    }
                    elementAfter={seeMoreButton()}
                    eventsQuery={GRAPHQL_QUERY_MY_SCHEDULE}
                    data-testid="mySchedule"
                    currentServerTime={currentServerTime}
                    limitDisplayedEvents={3}
                    showAllEvents={showAllEvents}
                    showSpecificDate={true}
                    enableScrollFromQueryParams={true}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                Your schedule is empty
                            </Typography>
                            <LinkButton
                                to={routes.memberSessions()}
                                sx={{ marginTop: 1.5 }}
                                variant="primary"
                                size="small"
                                fullWidth={false}
                            >
                                Explore events
                            </LinkButton>
                        </Box>
                    }
                />
                <EventListLoader
                    sx={{ mb: 4 }}
                    elementBefore={
                        <div className={classes.headerWithIconContainer}>
                            <RadioButtonCheckedIcon className={classes.headerIcon} />
                            <Typography
                                className={classNames(classes.noMargin, classes.colorGrey800)}
                                variant="h3"
                            >
                                Starting soon
                            </Typography>
                        </div>
                    }
                    data-testid="sessionsRightNow"
                    currentServerTime={currentServerTime}
                    eventsQuery={GRAPHQL_QUERY_UPCOMING_EVENTS}
                    queryOptions={{
                        variables: {
                            includeTypes: sessionTypesForSessionsRightNow,
                            startsWithinMinutes: 240,
                            limit: 6,
                        },
                    }}
                    filterEvents={filterSessionsRightNow}
                    emptyStateElement={
                        <Box className={classes.emptyStateCard}>
                            <Typography variant="h6" className={classes.colorGrey800}>
                                No events starting soon
                            </Typography>
                        </Box>
                    }
                    limitDisplayedEvents={3}
                />
            </PageWrapper>
            <AnnouncementsCarousel data-testid="announcementsCarousel" />
        </>
    );
};
