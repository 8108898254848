import { Sangha, UserProfile, SanghaMembership } from "@app/shared/types";
import { Button, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { externalImageSrc } from "app/images";
import { CSDialog } from "components/CSDialog";
import { FadeCarousel } from "components/FadeCarousel";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import { useState } from "react";
import UserProfileHighlight from "../profile/UserProfileHighlight";
import MemberDialog from "../sangha/MemberDialog";
import classNames from "classnames";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "inline-block",
    },
    modalTitle: {
        marginTop: theme.spacing(6),

        textAlign: "center",
    },
    button: {
        width: "max-content",
    },
    bottomBar: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        width: "100vw",
        boxShadow: `${MENU_SHADOW_HEIGHT}px 0 ${MENU_SHADOW_HEIGHT}px ${alpha(
            theme.palette.grey900,
            0.1,
        )}`,

        backgroundColor: theme.palette.neutralWarm,
    },
    bottomBarCol1: {
        display: "inline-block",
        width: "50%",
        textAlign: "right",
    },
    bottomBarCol2: {
        display: "inline-block",
        width: "50%",
    },
    fadeCarousel: {
        height: 32,
        width: 32,
        borderRadius: theme.borderRadius.circular,
        marginRight: theme.spacing(2),
        display: "inline-block",
        verticalAlign: "text-bottom",
    },
    card: {
        backgroundColor: theme.palette.background.contrast,
        color: theme.palette.grey700,
    },
    websiteLink: {
        color: theme.palette.grey700,
    },
    teacherLabel: {
        color: theme.palette.neutralWarm,
    },
    hideElement: {
        display: "none",
    },
    groupIcon: {
        marginRight: theme.spacing(1),
    },
}));

export const SpotsAvailableMemberList = (props: {
    sangha: Sangha;
    classes?: Partial<ReturnType<typeof useStyles>>;
}) => {
    const { sangha } = props;

    const dispatch = useDispatch();

    const classes = useStyles(props);
    const [dialogMember, setDialogMember] = useState<UserProfile | null>(null);

    return (
        <>
            <div className={classes.modalTitle}>
                <Typography variant="h2">The people you’ll bond with</Typography>
            </div>

            <UserProfileHighlight
                profile={{
                    ...sangha.teacher,
                    nickName: sangha.teacher?.name,
                    bio: sangha.teacher?.background,
                    isTeacher: true,
                }}
                onClick={() => {
                    dispatch(push(routes.teacherPage(sangha.teacher.id)));
                    analyticsTrack(`member.spotsAvailable.teacher_profile.opened`, {
                        sanghaId: sangha.id,
                        teacherName: sangha.teacher?.name,
                    });
                }}
                classes={{
                    card: classes.card,
                    websiteLink: classes.websiteLink,
                    teacherLabel: classes.teacherLabel,
                }}
            />

            {sangha.memberships?.map((membership: SanghaMembership, i: number) => (
                <UserProfileHighlight
                    key={membership?.user?.profile.id}
                    profile={membership?.user?.profile}
                    onClick={() => {
                        setDialogMember(membership?.user?.profile);
                        analyticsTrack(`member.spotsAvailable.member_profile.opened`, {
                            sangha: sangha.id,
                            clickedUser: membership?.user?.profile.id,
                        });
                    }}
                    classes={{
                        card: classes.card,
                        websiteLink: classes.websiteLink,
                        teacherLabel: classes.teacherLabel,
                    }}
                />
            ))}
            {dialogMember && (
                <MemberDialog
                    member={dialogMember}
                    open={dialogMember !== null}
                    onClose={() => setDialogMember(null)}
                />
            )}
        </>
    );
};

export const SpotsAvailableButton = (props: {
    sangha: Sangha;
    classes?: Partial<ReturnType<typeof useStyles>>;
}) => {
    const { sangha } = props;

    const classes = useStyles(props);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const pictures: string[] =
        sangha?.memberships
            ?.filter((membership) => membership?.user?.profile?.picture)
            .map((membership) => externalImageSrc(membership.user.profile.picture as string, 50)) ??
        [];
    return (
        <>
            {pictures.length > 0 ? (
                <FadeCarousel
                    images={pictures}
                    interval={3000}
                    classes={{
                        container: classes.fadeCarousel,
                        image: classes.fadeCarousel,
                    }}
                />
            ) : (
                <span className={classNames("material-symbols-rounded", classes.groupIcon)}>
                    group
                </span>
            )}
            <Button
                variant="tertiary"
                size="small"
                className={classes.button}
                onClick={() => {
                    setIsModalOpen(true);
                    analyticsTrack(`member.spotsAvailable.button.clicked`, {
                        sanghaId: sangha.id,
                    });
                }}
                data-testid="spotsAvailableButton"
            >
                {sangha?.openSpots ?? 0} of{" "}
                {(sangha?.memberships?.length ?? 0) + (sangha?.openSpots ?? 0)} spots available
            </Button>

            <CSDialog
                onClose={() => setIsModalOpen(false)}
                open={isModalOpen}
                transitionDuration={700}
                fullScreen={true}
            >
                <div data-testid="spotsAvailableModal">
                    <SpotsAvailableMemberList {...props} />
                </div>
            </CSDialog>
        </>
    );
};
