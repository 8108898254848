import {
    MEN_ONLY_SESSION_NOTE,
    POC_ONLY_SESSION_NOTE,
    EVENT_TYPES_FOR_TEACHERS,
} from "./constants";
import { SessionType } from "./types";

const getDefaultEventTitle = (eventType: SessionType, eventSanghaName?: string) => {
    switch (eventType) {
        case SessionType.SANGHA_SESSION:
            return `Your Mentorship Group (${eventSanghaName})`;
        case SessionType.INTEREST_GROUP:
            return `${eventSanghaName}`;
        case SessionType.COMMUNITY_SIT:
            return "Community Meditation";
        case SessionType.COMMUNITY_TALK:
            return "Community Talk";
        case SessionType.WELCOME_SESSION:
            return "Welcome & Orientation Session";
        case SessionType.SILENT_SIT:
            return "Silent Meditation";
        case SessionType.SILENT_MEDITATION_HALL:
            return "Silent Meditation Hall";
        case SessionType.CONFLICT_RESOLUTION_PRACTICE:
            return "Conflict Resolution Practice";
        case SessionType.QA_SESSION:
            return "Q&A Session";
        default:
            return "Unknown";
    }
};

interface CoreEventFields {
    title?: string | null;
    type: SessionType;
    notes?: string | null;
}

export const getEventTitle = ({
    event,
    sanghaName,
}: {
    event: CoreEventFields;
    sanghaName?: string;
}): string => {
    if (event.title) {
        return event.title;
    }

    const defaultTitle = getDefaultEventTitle(event.type, sanghaName);

    if (EVENT_TYPES_FOR_TEACHERS.includes(event.type)) {
        return event.notes || defaultTitle;
    }

    // Temporary fix to add POC session specific title
    if (event.notes === POC_ONLY_SESSION_NOTE) {
        return `${defaultTitle} for People of Color`;
    }

    if (event.notes === MEN_ONLY_SESSION_NOTE) {
        return `${defaultTitle} for Men`;
    }

    return defaultTitle;
};
