import { Sangha } from "@app/shared/types";
import { FilterSidebar } from "../filters/FilterSidebar";
import {
    FilterData,
    getAffinityFilterConfig,
    getAudienceFilterOptionsFromSanghas,
    getDayOfWeekFilterConfig,
    getTeacherFilterConfig,
    getTeacherFilterOptionsFromSanghas,
    getTimeOfDayFilterConfig,
} from "../filters/filterHelpers";

type MentorshipGroupFilterProps = {
    filters: FilterData;
    setFilters: (filters: FilterData) => void;
    groupCount?: number;
    resetFilters: VoidFunction;
    groups: Sangha[];
};

export const MentorshipGroupFilter = (props: MentorshipGroupFilterProps) => {
    const { filters, setFilters, groupCount, resetFilters, groups } = props;

    const teacherOptions = getTeacherFilterOptionsFromSanghas(groups);
    const audienceOptions = getAudienceFilterOptionsFromSanghas(groups);

    const dayOfWeekFilter = getDayOfWeekFilterConfig(filters, setFilters);
    const timeOfDayFilter = getTimeOfDayFilterConfig(filters, setFilters);
    const teacherFilter = getTeacherFilterConfig(filters, setFilters, teacherOptions);
    const affinityFilter = getAffinityFilterConfig(filters, setFilters, audienceOptions);

    if (!dayOfWeekFilter || !timeOfDayFilter || !teacherFilter || !affinityFilter) {
        return null;
    }

    return (
        <FilterSidebar
            filters={[dayOfWeekFilter, timeOfDayFilter, teacherFilter, affinityFilter]}
            groupCount={groupCount}
            resetFilters={resetFilters}
        />
    );
};
