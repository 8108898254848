import { Sangha } from "@app/shared/types";
import { FilterSidebar } from "../filters/FilterSidebar";
import { CheckboxItem } from "../filters/CheckboxListItem";
import { CheckboxList } from "../filters/CheckboxList";
import {
    getAudienceFilterOptionsFromSanghas,
    getTeacherFilterOptionsFromSanghas,
    getDayOfWeekFilterConfig,
    FilterData,
    getTimeOfDayFilterConfig,
    getTeacherFilterConfig,
    getAffinityFilterConfig,
    useInterestGroupTopicFilterOptionsFromSangha,
    useInterestGroupTopicFilterConfig,
} from "../filters/filterHelpers";

type InteresGroupFilterProps = {
    filters: FilterData;
    setFilters: (filters: FilterData) => void;
    groupCount?: number;
    resetFilters: VoidFunction;
    groups: Sangha[];
};

interface FilterCheckboxListProps {
    items: CheckboxItem[];
    selectedValues: string[];
    onChange: (selectedValue: string[]) => void;
    disabled?: boolean;
}

export const renderFilterCheckboxList = (props: FilterCheckboxListProps) => {
    return <CheckboxList {...props} />;
};

export const InterestGroupFilter = (props: InteresGroupFilterProps) => {
    const { filters, setFilters, groupCount, resetFilters, groups } = props;

    const teacherOptions = getTeacherFilterOptionsFromSanghas(groups);
    const audienceOptions = getAudienceFilterOptionsFromSanghas(groups);
    const topicOptions = useInterestGroupTopicFilterOptionsFromSangha(groups);

    const topicFilter = useInterestGroupTopicFilterConfig(filters, setFilters, topicOptions);

    const dayOfWeekFilter = getDayOfWeekFilterConfig(filters, setFilters);
    const timeOfDayFilter = getTimeOfDayFilterConfig(filters, setFilters);
    const teacherFilter = getTeacherFilterConfig(filters, setFilters, teacherOptions);

    if (!topicFilter || !dayOfWeekFilter || !timeOfDayFilter || !teacherFilter) {
        return null;
    }

    return (
        <FilterSidebar
            filters={[topicFilter, dayOfWeekFilter, timeOfDayFilter, teacherFilter]}
            groupCount={groupCount}
            resetFilters={resetFilters}
        />
    );
};
