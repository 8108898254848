import { useMutation, useQuery } from "@apollo/client";
import { getMentorshipGroupAddOnPrice, priceAfterDiscount } from "@app/shared/prices";
import { ProductFeature, Sangha, SanghaMembership } from "@app/shared/types";
import { dateTimeFromString } from "@app/shared/utils";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_ADD_ON_MENTORSHIP_GROUP,
    GRAPHQL_QUERY_MEMBERSHIPS,
    GRAPHQL_QUERY_MY_SANGHAS,
    GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS,
    GRAPHQL_QUERY_SANGHA_DETAILS,
    GRAPHQL_QUERY_USER,
} from "app/queries";
import { routes } from "app/routes";
import { LIMITED_WIDTH } from "app/styles";
import { displayPrice } from "components/displayPrice";
import { GenericErrorPage } from "components/GenericErrorPage";
import { OptionBox } from "components/OptionBox";
import PageWrapper from "components/PageWrapper";
import { fetchUser } from "features/auth/auth";
import { LoadingPage } from "features/pages/LoadingPage";
import SanghaGroupInfo from "features/sangha/SanghaGroupInfo";
import SanghaTimeslot from "features/sangha/SanghaTimeslot";
import { useUserTimezone } from "hooks/useUserTimezone";
import { TwoColumnLayout } from "layouts/TwoColumnLayout";
import { DateTime } from "luxon";
import { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router";
import { NavLink } from "react-router-dom";
import { MemberContext } from "../MemberContext";
import { membershipUsesFreeTrialInternally } from "../membership/membershipHelpers";
import { MentorshipGroupAddOnSuccess } from "./MentorshipGroupAddOnSuccess";
import { SetupProgressBar } from "features/setup/SetupProgressBar";
import { isUserInMMTSignupFlow, isUserInSetupFlow } from "features/signup/signupHelpers";
import { CSDialog } from "components/CSDialog";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: "flex",
        gap: theme.spacing(2),
        flexWrap: "wrap",
    },
}));

export const ReviewMentorshipAddOn = () => {
    const { memberships } = useContext(MemberContext);
    const membership = memberships[0];
    const history = useHistory();
    const classes = useStyles();

    const { sanghaId } = useParams<{ sanghaId: string }>();

    const dispatch = useDispatch();

    const [addOnMentorshipGroup, { error: saveError, loading: saveLoading, data: saveData }] =
        useMutation(GRAPHQL_MUTATION_ADD_ON_MENTORSHIP_GROUP, {
            variables: {
                sanghaId,
            },
            update: () => {
                dispatch(fetchUser());
            },
            refetchQueries: [
                {
                    query: GRAPHQL_QUERY_USER,
                },
                {
                    query: GRAPHQL_QUERY_MEMBERSHIPS,
                },
                {
                    query: GRAPHQL_QUERY_MY_SANGHAS,
                },
                {
                    query: GRAPHQL_QUERY_PENDING_SANGHA_MEMBERSHIPS,
                },
            ],
        });

    const {
        data: sanghaData,
        loading: sanghaLoading,
        error: sanghaError,
    } = useQuery(GRAPHQL_QUERY_SANGHA_DETAILS, {
        variables: { id: sanghaId },
    });

    const sangha = sanghaData?.sangha as Sangha | undefined;

    const timeZone = useUserTimezone();

    useEffect(() => {
        if (saveData?.joinGroup?.id) {
            analyticsTrack("mentorshipGroup.addOn.success", {
                groupId: sanghaId,
            });
        }
    }, [saveData?.joinGroup?.id]);

    const memberContext = useContext(MemberContext);
    const activeMemberships = memberContext.memberships;
    const activeMembership = activeMemberships[0];

    const isMemberOnFreeTrial = membershipUsesFreeTrialInternally(membership);
    const [freeTrialInfoPopupOpen, setFreeTrialInfoPopupOpen] = useState(false);

    const location = useLocation();
    const isInSetupFlow = isUserInSetupFlow(location);

    if (sanghaLoading || saveLoading || !sangha) {
        return <LoadingPage />;
    }

    if (sanghaError || saveError) {
        return <GenericErrorPage />;
    }

    if (saveData?.joinGroup?.id) {
        return (
            <>
                {isInSetupFlow && <SetupProgressBar currentStep={4} />}
                <MentorshipGroupAddOnSuccess
                    sanghaMembership={saveData?.joinGroup as SanghaMembership}
                />
            </>
        );
    }

    // If member is on a legacy plan or has multiple memberships, block them since we can't compute a price for them
    if (
        activeMemberships.length > 1 ||
        !membership.billingChoice.plan.features.includes(ProductFeature.ELIGIBLE_FOR_ADD_ONS)
    ) {
        return (
            <PageWrapper>
                <Typography variant="body1">
                    Ah, you're on one of our legacy memberships. In order to add a mentorship group,
                    we'll need to switch your membership to our new one. Please{" "}
                    <NavLink to={routes.contactUs()}>contact support</NavLink> and we'll take care
                    of it.
                </Typography>
            </PageWrapper>
        );
    }

    if (!sangha.upcomingSessionForInvitation) {
        return <GenericErrorPage />;
    }

    const firstSessionDate = dateTimeFromString(sangha.upcomingSessionForInvitation, timeZone);

    const billingStartDate = firstSessionDate.minus({ weeks: 1 });

    const isInMMTSignupFlow = isUserInMMTSignupFlow() && isUserInSetupFlow(location);

    const handleMentorshipGroupAddOn = async () => {
        analyticsTrack("mentorshipGroup.addOn.submit", {
            firstSessionDate: firstSessionDate,
            groupId: sangha.id,
            teacherName: sangha.teacher.name,
        });
        await addOnMentorshipGroup();
    };

    const renderButtons = () => {
        return (
            <div className={classes.buttonContainer}>
                <Button
                    variant="primary"
                    onClick={() =>
                        isMemberOnFreeTrial
                            ? setFreeTrialInfoPopupOpen(true)
                            : handleMentorshipGroupAddOn()
                    }
                    disableElevation
                >
                    Add Mentorship Group
                </Button>
                <Button
                    variant="secondary"
                    onClick={async () => {
                        analyticsTrack("mentorshipGroup.addOn.goBack", {
                            firstSessionDate: firstSessionDate,
                            groupId: sangha.id,
                            teacherName: sangha.teacher.name,
                        });
                        isInMMTSignupFlow
                            ? history.push(routes.setupAllMentorshipGroups())
                            : history.push(routes.memberSanghas());
                    }}
                    disableElevation
                >
                    Go back
                </Button>
            </div>
        );
    };

    const renderPrice = () => {
        const currentPrice = membership.billingChoice.plan.baseAmount;
        const addOnPrice = getMentorshipGroupAddOnPrice(
            sangha.eligibleMembershipTypes[0],
            activeMembership.billingChoice.plan.cycle,
        );

        if (activeMembership.discount && !activeMembership.discount.percentOff) {
            const discountedPrice = priceAfterDiscount(
                currentPrice + addOnPrice,
                activeMembership.discount,
            );
            return displayPrice(discountedPrice);
        }

        return displayPrice(currentPrice + addOnPrice);
    };

    const renderBillingStartDate = () => {
        if (billingStartDate < DateTime.now()) {
            return "immediately";
        }

        return `on ${billingStartDate.toLocaleString(DateTime.DATE_FULL)}`;
    };

    const renderBillingExplanation = () => {
        return (
            <OptionBox readOnly={true}>
                <Typography variant="body1" sx={{ mb: 0 }}>
                    Your new updated membership will be <strong>{renderPrice()}</strong> every{" "}
                    {activeMembership.billingChoice.plan.cycle}.{" "}
                    {!!activeMembership.discount?.percentOff &&
                        `Your current ${activeMembership.discount.percentOff}% discount will be
                        removed when you add a mentorship group.`}
                </Typography>

                <Typography variant="body1" sx={{ mb: 0 }}>
                    This change will be applied {renderBillingStartDate()}
                </Typography>
            </OptionBox>
        );
    };

    return (
        <>
            {isInSetupFlow && <SetupProgressBar currentStep={4} />}
            <TwoColumnLayout secondaryColumn={renderButtons()}>
                <div>
                    <Typography variant="h2">Awesome. Does this look alright?</Typography>

                    <SanghaGroupInfo sangha={sangha} />

                    <SanghaTimeslot sangha={sangha} timeZone={timeZone} isInvitation={true} />

                    <OptionBox readOnly={true}>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            Your first session will be on{" "}
                            <strong>
                                {firstSessionDate.toLocaleString(DateTime.DATETIME_FULL)}
                            </strong>
                            . (We'll send you a reminder)
                        </Typography>
                    </OptionBox>

                    {renderBillingExplanation()}
                </div>
            </TwoColumnLayout>
            <CSDialog
                open={freeTrialInfoPopupOpen}
                onClose={() => setFreeTrialInfoPopupOpen(false)}
            >
                <Typography variant="h3">
                    Your free trial will end & your paid membership will begin
                </Typography>
                <Typography variant="body1">
                    This is because mentorship groups are a paid-for addition to a basic membership.
                </Typography>
                <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2, mt: 4 }}>
                    <Button variant="primary" onClick={handleMentorshipGroupAddOn}>
                        Continue
                    </Button>
                    <Button variant="secondary" onClick={() => setFreeTrialInfoPopupOpen(false)}>
                        Cancel
                    </Button>
                </Box>
            </CSDialog>
        </>
    );
};
