import { MembershipType, Sangha } from "@app/shared/types";
import { Button, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { externalImageSrc } from "app/images";
import { titleFont } from "app/theme";
import { SanghaDayAndTimeDescription } from "features/sangha/SanghaDayAndTimeDescription";
import { useUserTimezone } from "hooks/useUserTimezone";
import { DateTime } from "luxon";
import { useState } from "react";
import LegacyEventCard from "components/LegacyEventCard";
import { SpotsAvailableButton } from "./SpotsAvailableButton";
import { PRIMARY_ADMIN_ACCOUNT_NAME } from "@app/shared/constants";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => ({
    card: {
        boxSizing: "border-box",
    },
    teacherLink: {
        verticalAlign: "baseline",
        color: "inherit",
        textDecoration: "inherit",
        textTransform: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
    },
    immersionPromotionLink: {},
    eventName: {
        marginBottom: theme.spacing(1),
    },
    eventTime: {
        marginBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
    },
    col2: {
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },
    description: {
        display: "inline-block",
        marginBottom: theme.spacing(2),
    },
    messageWrapper: {
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    eventTimeDisplay: {
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        display: "inline-block",
        [theme.breakpoints.down("md")]: {
            display: "block",
            marginRight: 0,
        },
    },
    actionBar: {
        // display: "block",
    },
    spotsAvailableButton: {
        marginBottom: theme.spacing(2),
        color: "inherit",
        textDecoration: "inherit",
        textTransform: "inherit",
        fontWeight: "inherit",
        fontSize: "inherit",
    },
    claimYourSpotButton: {
        whiteSpace: "nowrap",
    },
    groupStatus: {},
    cardContent: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        [theme.breakpoints.down("lg")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    mainContent: {
        height: "100%",
    },
    buttonContainer: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: theme.spacing(1),
        [theme.breakpoints.down("lg")]: {
            flexWrap: "wrap",
        },
    },
}));

export const ImmersionGroupCard = (props: {
    sangha: Sangha;
    nextSessionStartTime?: DateTime;
    nextSessionEndTime?: DateTime;
    ref?: React.Ref<HTMLDivElement>;
    handleOnClaimSpot?: () => void;
    claimButtonText?: React.ReactNode;
    hideLearnMore?: boolean;
    hideSpotsAvailable?: boolean;
    extraButton?: React.ReactNode;
    isAddOnDisabled?: boolean;
}) => {
    const classes = useStyles();

    const {
        sangha,
        handleOnClaimSpot,
        ref,
        claimButtonText,
        hideLearnMore,
        hideSpotsAvailable,
        isAddOnDisabled,
    } = props;

    const dispatch = useDispatch();

    const timezone = useUserTimezone();

    const teacher = sangha.teacher;

    const nextSessionStartTime =
        props.nextSessionStartTime ||
        (sangha.nextSession && DateTime.fromISO(sangha.nextSession.time));
    const nextSessionEndTime =
        props.nextSessionEndTime ||
        (sangha.nextSession && DateTime.fromISO(sangha.nextSession.endTime));

    if (!nextSessionStartTime || !nextSessionEndTime) {
        return null;
    }

    const renderSpotsAvailable = () => {
        return (
            <SpotsAvailableButton
                sangha={sangha}
                classes={{ button: classes.spotsAvailableButton }}
            />
        );
    };

    const renderButtons = () => (
        <div className={classes.buttonContainer}>
            {props.extraButton}

            {!hideLearnMore && (
                <Button
                    variant="secondary"
                    size="small"
                    fullWidth
                    className={classes.immersionPromotionLink}
                    onClick={handleOnClaimSpot}
                >
                    Learn more
                </Button>
            )}

            {claimButtonText && (
                <Button
                    fullWidth
                    className={classes.claimYourSpotButton}
                    variant="primary"
                    size="small"
                    onClick={handleOnClaimSpot}
                    disabled={isAddOnDisabled}
                >
                    {claimButtonText}
                </Button>
            )}
        </div>
    );

    const getAdditionalInfo = () => {
        if (sangha.eligibleMembershipTypes[0] === MembershipType.mmtcp) {
            return "For Meditation Teachers";
        }
        if (sangha.audienceDisplay && sangha.audienceDisplay !== "all meditators") {
            switch (sangha.audienceDisplay) {
                case "women":
                    return "For Women";
                case "people of color":
                    return "For People of Color";
                case "60+ year olds":
                    return "For 60+ year olds";
                case "LGBTQIA+":
                    return "For LGBTQIA+";
                case "experienced":
                    return "For Experienced Meditators";
                case "psychotherapists":
                    return "For Psychotherapists";
                case "mindful leadership":
                    return "For Mindful Leaders";
                default:
                    return `For ${sangha.audienceDisplay}`;
            }
        }
    };

    const getTitle = () => {
        const additionalInfo = getAdditionalInfo();

        return (
            <>
                <div>
                    <Typography variant="h3" className={classes.eventName}>
                        Mentorship Group
                        {additionalInfo && ` ${additionalInfo}`}
                    </Typography>
                </div>
            </>
        );
    };

    const getTeacherName = () => {
        return (
            <>
                {" "}
                {teacher.name === PRIMARY_ADMIN_ACCOUNT_NAME ? (
                    "Mystery Teacher"
                ) : (
                    <Button
                        variant="tertiary"
                        className={classes.teacherLink}
                        onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                    >
                        {`${teacher.name}`}
                    </Button>
                )}
            </>
        );
    };

    return (
        <>
            <LegacyEventCard
                containerAttributes={{
                    ref: ref,
                    "data-testid": "immersionGroupCard",
                    "data-groupid": sangha.id,
                }}
                pictures={[teacher.picture ? externalImageSrc(teacher.picture, 260) : "EMPTY"]}
                pictureOnClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                information={
                    <div className={classes.cardContent}>
                        <div className={classes.mainContent}>
                            <Typography variant="h4" className={classes.eventName}>
                                {getTitle()}
                            </Typography>
                            <Typography variant="caption">With: {getTeacherName()}</Typography>
                            <div className={classes.eventTime}>
                                <Typography
                                    variant="caption"
                                    className={classes.eventTimeDisplay}
                                    data-testid="eventTime"
                                >
                                    <SanghaDayAndTimeDescription
                                        sangha={sangha}
                                        userTimeZone={timezone}
                                        renderTimeZone={true}
                                        overrideStartTime={nextSessionStartTime}
                                        overrideEndTime={nextSessionEndTime}
                                    />
                                </Typography>
                            </div>
                            <Typography variant="caption" className={classes.description}>
                                Meet with the same group and teacher each week on your shared
                                journey.{" "}
                            </Typography>
                            <Typography variant="caption">
                                {sangha.memberships &&
                                    !hideSpotsAvailable &&
                                    renderSpotsAvailable()}
                            </Typography>
                        </div>
                        {renderButtons()}
                    </div>
                }
            />
        </>
    );
};

export default ImmersionGroupCard;
