import { Box, Button, Typography } from "@mui/material";
import { CSCheckboxField } from "components/CSCheckboxField";
import PageWrapper from "components/PageWrapper";
import { NavLink } from "features/navigation/NavLink";
import { LoungeOpenedSuccessMessage } from "./LoungeOpenedSuccessMessage";
import { routes } from "app/routes";
import classNames from "classnames";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import {
    GRAPHQL_MUTATION_ACCEPT_LOUNGE_GUIDELINES,
    GRAPHQL_MUTATION_INVITE_MEMBER_TO_CIRCLE,
} from "app/queries";
import { LOUNGE_CIRCLE_URL } from "./LoungePage";
import { theme } from "app/theme";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        textAlign: "right",
        marginTop: theme.spacing(2),
    },
}));

export const LoungeWelcomeMessage = () => {
    const classes = useStyles();

    const [showCommunityGuidelinesInfo, setShowCommunityGuidelinesInfo] = useState(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [guidelinesAccepted, setGuidelinesAccepted] = useState(false);

    const [inviteMemberToCircleMutation] = useMutation(GRAPHQL_MUTATION_INVITE_MEMBER_TO_CIRCLE, {
        variables: {
            spaceIds: ["1249150"],
            spaceGroupIds: ["436186"],
        },
    });
    const [acceptLoungeGuidelinesMutation] = useMutation(GRAPHQL_MUTATION_ACCEPT_LOUNGE_GUIDELINES);
    const handleWelcomeBannerClick = () => setShowCommunityGuidelinesInfo(true);
    const handleOpeningCircleClick = async () => {
        await inviteMemberToCircleMutation();
        await acceptLoungeGuidelinesMutation();
        window.open(LOUNGE_CIRCLE_URL, "_blank");
        setShowSuccessMessage(true);
    };
    return showSuccessMessage ? (
        <LoungeOpenedSuccessMessage />
    ) : (
        <PageWrapper>
            {showCommunityGuidelinesInfo ? (
                <Box>
                    <Typography variant="h2">Continue following our guidelines</Typography>
                    <Typography variant="body1">
                        Our Banyan guidelines continue to apply in the Banyan Lounge. Please check
                        the full{" "}
                        <NavLink to={routes.communityGuidelines()}>guidelines here</NavLink>.
                    </Typography>
                    <CSCheckboxField
                        value={guidelinesAccepted}
                        onChange={setGuidelinesAccepted}
                        label="I accept to continue following the guidelines."
                    />
                    {showSuccessMessage ? (
                        <LoungeOpenedSuccessMessage />
                    ) : (
                        <div className={classes.buttonContainer}>
                            <Button
                                variant="primary"
                                onClick={() => handleOpeningCircleClick()}
                                disabled={!guidelinesAccepted}
                            >
                                Explore the lounge
                                <span
                                    className={classNames("material-symbols-rounded")}
                                    style={{ marginLeft: theme.spacing(1) }}
                                >
                                    open_in_new
                                </span>
                            </Button>
                        </div>
                    )}
                </Box>
            ) : (
                <Box>
                    <Typography variant="h2">WELCOME TO THE BANYAN LOUNGE</Typography>
                    <Typography variant="body1">
                        This is where Banyan members hang out when we're not in our weekly groups.
                        Come chat, learn, make friends, and practice more often.
                    </Typography>
                    <Typography variant="h4">What To Expect</Typography>
                    <Typography variant="body1">
                        <ul>
                            <li>A community feed where you can post and respond to others</li>
                            <li>Optional member directory and private messaging</li>
                            <li>Regular mindfulness prompts and practices</li>
                            <li>Courses to further your understanding and your practice</li>
                        </ul>
                    </Typography>
                    {/* <Typography variant="h4">See How It Works</Typography>
        VIDEOPLACEHOLDER */}
                    <div className={classes.buttonContainer}>
                        <Button variant="primary" onClick={() => handleWelcomeBannerClick()}>
                            Continue
                        </Button>
                    </div>
                </Box>
            )}
        </PageWrapper>
    );
};
