import { routes } from "app/routes";
import { NavLink } from "features/navigation/NavLink";
import { useLogEventOnLoad } from "hooks/useLogEventOnLoad";
import { useLocation } from "react-router";
import { useEffect, useRef } from "react";
import { useGenericErrorMessageVisibility } from "./GenericErrorMessageContextProvider";
import { v4 as uuidv4 } from "uuid";

export const GenericErrorMessage = (props: { alternateInstruction?: string }) => {
    const reactRouterLocation = useLocation();
    const { registerGenericErrorMessage, errorShouldBeVisible } =
        useGenericErrorMessageVisibility();
    const errorMessageId = useRef<string>(uuidv4());

    useLogEventOnLoad("generic.error.message", {
        page: reactRouterLocation.pathname, // Kept for backwards events compatibility
        location: reactRouterLocation.pathname,
        search: reactRouterLocation.search,
        hash: reactRouterLocation.hash,
        referrer: document.referrer,
    });

    useEffect(() => {
        registerGenericErrorMessage(errorMessageId.current);
    }, [errorMessageId.current]);

    if (errorShouldBeVisible(errorMessageId.current) === false) {
        return null;
    }

    return (
        <>
            {props.alternateInstruction ??
                "We encountered an error. Please try reloading the page. If the problem persists, "}
            <NavLink to={routes.contactUs()}> reach out here</NavLink>.
        </>
    );
};
