import { useMutation } from "@apollo/client";
import { Button, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { GRAPHQL_MUTATION_POST_MESSAGE, GRAPHQL_QUERY_MESSAGE_THREAD } from "app/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { useEffect, useRef, useState } from "react";
import * as React from "react";
import messageSendIcon from "images/icons/message-send-icon.svg";
import _ from "lodash";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { CSTextField } from "components/CSTextField";

const bottomSpacing = 10;
const bottomSpacingMobile = 8;

const useStyles = makeStyles((theme) => ({
    root: {
        position: "sticky",
        bottom: 0,
        marginLeft: theme.spacing(-1),
        marginRight: theme.spacing(-1),
        paddingBottom: theme.spacing(bottomSpacing),
        backgroundColor: theme.palette.background.default,

        [theme.breakpoints.down("md")]: {
            paddingBottom: theme.spacing(bottomSpacingMobile),
        },
    },

    messageInput: {},

    messageSendIcon: {
        marginRight: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            marginRight: -5,
        },
    },

    sendButton: {
        position: "absolute",
        bottom: theme.spacing(bottomSpacing),
        right: theme.spacing(-2),
        transition: "opacity 500ms ease",

        "&:disabled": {
            opacity: 0,
        },

        [theme.breakpoints.up("lg")]: {
            right: theme.spacing(-4),
        },

        [theme.breakpoints.down("md")]: {
            padding: theme.spacing(2),
            bottom: theme.spacing(bottomSpacingMobile),
        },
    },
}));

export const MessagePostPanel = (props: { sanghaId: string; logMessagePosted: VoidFunction }) => {
    const classes = useStyles();
    const query = useQueryParams();
    const isMobile = useBreakpointQuery("sm");

    const { sanghaId } = props;

    const rsvp = query.rsvp ? (query.rsvp as string) : "";
    const notAttending = Boolean(rsvp && rsvp === "no");
    const defaultMessage = notAttending ? "I will be missing this week's session" : "";
    const messageInputFocused = notAttending || Boolean(query.respond);
    const [message, setMessage] = useState(defaultMessage);

    const [postMessageMutation, { loading: isSending, error: messageError }] = useMutation(
        GRAPHQL_MUTATION_POST_MESSAGE,
        {
            refetchQueries: [
                {
                    query: GRAPHQL_QUERY_MESSAGE_THREAD,
                    variables: { sanghaId },
                },
            ],
        },
    );

    const onPostMessage = async (ev: React.MouseEvent<any>) => {
        ev.preventDefault();

        postMessageMutation({
            variables: { sanghaId, content: message },
        })
            .then(() => {
                setMessage("");
            })
            .catch(_.noop);
        props.logMessagePosted();
    };
    const messageInputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        const messageInputDOM = messageInputRef.current;
        if (notAttending && messageInputDOM) {
            messageInputDOM.scrollIntoView();
            messageInputDOM.selectionStart = messageInputDOM.value.length;
            messageInputDOM.selectionEnd = messageInputDOM.value.length;
        }
    }, [notAttending]);

    return (
        <div className={classes.root}>
            <CSTextField
                InputProps={{
                    classes: { root: classes.messageInput },
                }}
                inputProps={{ "data-testid": "postMessageInput" }}
                placeholder="Your message"
                label="Your message"
                fullWidth
                multiline
                disabled={isSending}
                rows={isMobile ? undefined : 3}
                autoFocus={messageInputFocused || false}
                inputRef={messageInputRef}
                value={isSending ? "Posting..." : message}
                onChange={(value) => setMessage(value)}
            />
            <Button
                type="submit"
                data-testid="postMessageSubmit"
                variant="primary"
                disabled={isSending || !message.trim()}
                onClick={onPostMessage}
                className={classes.sendButton}
            >
                <img src={messageSendIcon} className={classes.messageSendIcon} alt="" />
                {isMobile ? null : "Send"}
            </Button>
            {messageError && (
                <Typography variant="body2" color="error">
                    Something went wrong posting your message
                </Typography>
            )}
        </div>
    );
};

export default MessagePostPanel;
