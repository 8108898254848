import { useContext } from "react";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { LoungeOpenedSuccessMessage } from "./LoungeOpenedSuccessMessage";
import { LoungeWelcomeMessage } from "./LoungeWelcomeMessage";
import { Typography } from "@mui/material";
import PageWrapper from "components/PageWrapper";

export const LOUNGE_CIRCLE_URL = "https://community.banyantogether.com/c/feed/";

export const LoungePage = () => {
    const user = useContext(LoggedInUserProfileContext);

    if (user.profile.acceptedLoungeGuidelines) {
        const loungeTab = window.open(LOUNGE_CIRCLE_URL, "_blank");
        if (!loungeTab) {
            window.location.href = LOUNGE_CIRCLE_URL;
            return (
                <PageWrapper>
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                        You will be redirected...
                    </Typography>
                </PageWrapper>
            );
        }
        return <LoungeOpenedSuccessMessage />;
    }

    return <LoungeWelcomeMessage />;
};
