import { Sangha, SanghaType } from "@app/shared/types";
import { Button, ButtonProps } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { routes } from "app/routes";
import { useHistory, useLocation } from "react-router";

const useStyles = makeStyles((theme) => ({}));

export const JoinInterestGroupButton = (props: ButtonProps & { interestGroup: Sangha }) => {
    const { interestGroup, ...buttonProps } = props;
    const history = useHistory();
    const groupIsFull = interestGroup.availableSpots <= 0;

    const location = useLocation();

    const filterQuery = new URLSearchParams(location.search).toString();

    return (
        <Button
            {...buttonProps}
            variant={props.variant}
            size="small"
            data-testid="joinGroupButton"
            disabled={groupIsFull}
            onClick={() => {
                analyticsTrack("Button Clicked", {
                    label: "Join",
                    id: interestGroup.id,
                    type: SanghaType.InterestGroup,
                    location: "AllInterestGroups",
                });
                history.push({
                    pathname: routes.memberAddInterestGroup(interestGroup.id),
                    search: filterQuery ? `?${filterQuery}` : "",
                });
            }}
        >
            {groupIsFull ? "Group is full" : "Know more"}
        </Button>
    );
};
