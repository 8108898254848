import { TEACHER_SANGHA_ID } from "@app/shared/constants";
import { Sangha, SanghaType, SessionStarted } from "@app/shared/types";
import { Typography, useTheme } from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { titleFont } from "app/theme";
import { LinkButton } from "features/navigation/LinkButton";
import { useCurrentServerTime } from "hooks/useCurrentServerTime";
import penNibIcon from "images/icons/pen-nib.svg";
import { DateTime } from "luxon";
import { useContext, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { routes } from "../../../app/routes";
import { selectIsAdmin, selectIsTeacher } from "../../auth/auth";
import { LoggedInUserProfileContext } from "../../auth/AuthRoute";
import { NavLink } from "../../navigation/NavLink";
import { SanghaDescription } from "../../sangha/SanghaDescription";
import { getSanghaGroupDescriptor } from "../community/shared";
import { isSessionStarted } from "./isSessionStarted";
import { SanghaJoinButton } from "./SanghaJoinButton";
import { SanghaStartButton } from "./SanghaStartButton";

const useStyles = makeStyles((theme) => ({
    root: {},

    sessionButtons: {
        marginTop: theme.spacing(2),
    },

    button: {
        marginBottom: theme.spacing(2),

        "&:last-child": {
            marginBottom: 0,
        },
    },

    icon: {
        marginRight: theme.spacing(2),
    },
}));

const dateTimeFromString = (timeString?: string) =>
    timeString ? DateTime.fromISO(timeString) : null;

// Always enable the start button for the shared sits sangha since we use a weekly sangha for a daily session there
const forceEnableSessionButtons = (sangha: Sangha) => {
    return sangha.id === "9S06vp636MH4hNgRg3g2";
};

export const SanghaSessionPanel = (props: {
    sangha: Sangha;
    hasReflectionQuestion: boolean;
    classes?: any;
}) => {
    const classes = useStyles(props);
    const theme = useTheme();

    const { sangha, hasReflectionQuestion } = props;
    const user = useContext(LoggedInUserProfileContext);

    const isTeacher = useSelector(selectIsTeacher);
    const isAdmin = useSelector(selectIsAdmin);

    const nextSession = sangha.nextSession;

    const nextSessionStartTime = useMemo(
        () => dateTimeFromString(nextSession?.time),
        [nextSession?.time],
    );
    const nextSessionEndTime = useMemo(
        () => dateTimeFromString(nextSession?.endTime),
        [nextSession?.endTime],
    );

    const currentServerTime = useCurrentServerTime();
    const sessionStartedStatus = isSessionStarted({
        sessionStart: nextSessionStartTime,
        sessionEnd: nextSessionEndTime,
        currentServerTime,
    });

    const sessionInProgress = sessionStartedStatus === SessionStarted.IN_PROGRESS;
    const sessionStartingSoon = sessionStartedStatus === SessionStarted.ABOUT_TO_START;
    const sessionCancelled = nextSession?.cancelled;

    const enableSessionButtons =
        isAdmin ||
        (!sessionCancelled && (sessionInProgress || sessionStartingSoon)) ||
        forceEnableSessionButtons(sangha);

    useEffect(() => {
        const eventName = enableSessionButtons ? "member.sangha.sessionButtonsEnabled" : "member.sangha.sessionButtonsDisabled";
        analyticsTrack(
            eventName,
            {
                sessionStartTime: nextSessionStartTime?.toISO(),
                localTime: DateTime.now().toISO(),
            },
        );
    }, [enableSessionButtons, nextSessionStartTime]);

    const renderDescription = () => {
        if (sessionInProgress || sessionStartingSoon) {
            let descriptionText = `Your ${getSanghaGroupDescriptor(sangha)} is about to begin`;

            if (sessionCancelled) {
                descriptionText = "Today's session is cancelled";
            } else if (sessionInProgress) {
                descriptionText = `Your ${getSanghaGroupDescriptor(sangha)} session has begun`;
            }

            return <Typography variant="body1">{descriptionText}</Typography>;
        } else {
            return (
                <SanghaDescription
                    sangha={sangha}
                    timeZone={user.timeZone || DateTime.local().zoneName}
                    classes={{}}
                />
            );
        }
    };

    const renderWarnings = () => {
        if (!nextSession || !nextSessionStartTime) {
            return (
                <MuiAlert
                    variant="outlined"
                    severity="warning"
                    style={{ margin: theme.spacing(2, 0) }}
                >
                    No next session has been scheduled for this group. Please{" "}
                    <NavLink to={routes.contactUs()}>contact us</NavLink> if you think this is a
                    mistake.
                </MuiAlert>
            );
        }

        // If the session is starting in the next 23 hours, but there's no zoom info available, show an error (we return zoom info for sessions starting within the next day)
        if (!nextSession.zoomInfo && nextSessionStartTime < DateTime.now().plus({ hours: 23 })) {
            return (
                <MuiAlert
                    variant="outlined"
                    severity="error"
                    style={{ margin: theme.spacing(2, 0) }}
                >
                    We're having trouble communicating with Zoom. Please refresh the page or{" "}
                    <NavLink to={routes.contactUs()}>contact us</NavLink> if you continue
                    experiencing this issue.
                </MuiAlert>
            );
        }
    };

    return (
        <div className={classes.root}>
            {renderDescription()}
            {renderWarnings()}
            <div className={classes.sessionButtons}>
                {(isTeacher || isAdmin) && (
                    <div className={classes.button}>
                        <SanghaStartButton sangha={sangha} disabled={!enableSessionButtons} />
                    </div>
                )}
                {(!isTeacher || sangha.id === TEACHER_SANGHA_ID) && (
                    <div className={classes.button}>
                        <SanghaJoinButton sangha={sangha} disabled={!enableSessionButtons} />
                    </div>
                )}
                {(isTeacher || isAdmin) &&
                    !sessionInProgress &&
                    !sessionStartingSoon &&
                    !hasReflectionQuestion && (
                        <div className={classes.button}>
                            <LinkButton
                                data-testid="takeawaysButton"
                                variant="primary"
                                to={routes.teacherPostSession(sangha.id)}
                            >
                                <img height={25} className={classes.icon} src={penNibIcon} alt="" />
                                Write reflections
                            </LinkButton>
                        </div>
                    )}
            </div>
        </div>
    );
};
