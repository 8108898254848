import { Sangha, SanghaSessionCycle } from "@app/shared/types";
import {
    firstSessionForScheduleAfterDate,
    formatTimestamp,
    humanReadableTimeZone,
    nthMonthlyWeekdayForDate,
    timeDifferencesBetweenZonesDuringYear,
} from "@app/shared/utils";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { InfoPopup } from "components/InfoPopup";
import { getSanghaGroupDescriptor } from "features/member/community/shared";
import _ from "lodash";
import { DateTime } from "luxon";
import { SanghaDayAndTimeDescription } from "./SanghaDayAndTimeDescription";

const useStyles = makeStyles((theme) => ({
    root: {},

    descriptionText: {
        margin: theme.spacing(0),
    },
}));

const dayOfWeek = (date: DateTime, timezone?: string) =>
    formatTimestamp(date.toMillis(), {
        weekday: "long",
        timeZone: timezone || date.zoneName,
    });

const timeOfDay = (date: DateTime, timezone?: string) => {
    return formatTimestamp(date.toMillis(), {
        hour: "numeric",
        minute: "numeric",
        timeZone: timezone || date.zoneName,
    });
};

const weekOfMonthDisplayForSession = (date: DateTime) => {
    const weekOfMonth = nthMonthlyWeekdayForDate(date);
    return {
        1: "first",
        2: "second",
        3: "third",
        4: "fourth",
        5: "fifth",
    }[weekOfMonth];
};

export interface SanghaDescriptionProps {
    sangha: Sangha;
    timeZone: string;
    isInvitation?: boolean;
    classes?: any;
}

export const SanghaDescription = (props: SanghaDescriptionProps) => {
    const { sangha, timeZone, isInvitation } = props;
    const classes = useStyles(props);

    const nextSession = firstSessionForScheduleAfterDate(
        DateTime.fromISO(sangha.firstSessionDate),
        DateTime.local(),
        sangha.timeZone,
        sangha.cycle,
    );

    const originalTimeZone = sangha.timeZone;
    const originalDayOfWeek = dayOfWeek(nextSession, originalTimeZone);
    const originalTimeOfDay = timeOfDay(nextSession, originalTimeZone);

    const timeDifferences = timeDifferencesBetweenZonesDuringYear(
        originalTimeZone,
        timeZone,
        nextSession,
    );

    const affectedByDSTChanges = timeDifferences.length > 1;

    const renderTimeDifferenceRange = () => {
        const minimumTime = nextSession.plus({
            minutes: _.first(timeDifferences),
        });
        const maximumTime = nextSession.plus({
            minutes: _.last(timeDifferences),
        });

        if (minimumTime?.weekday !== maximumTime?.weekday) {
            return `between ${dayOfWeek(minimumTime)} ${timeOfDay(minimumTime)} and ${dayOfWeek(
                maximumTime,
            )} ${timeOfDay(maximumTime)}`;
        } else {
            return `between ${timeOfDay(minimumTime)} and ${timeOfDay(maximumTime)}`;
        }
    };

    const originalTimeDescription = () => {
        if (sangha.cycle === "weekly") {
            return `${originalDayOfWeek}s at ${originalTimeOfDay}`;
        } else {
            const weekOfMonth = weekOfMonthDisplayForSession(nextSession);
            return `${weekOfMonth} ${originalDayOfWeek}s at ${originalTimeOfDay}`;
        }
    };

    const renderTimeZoneInfo = () => {
        const currentTimeZoneDisplay = `This is in ${humanReadableTimeZone(timeZone)}`;
        if (affectedByDSTChanges) {
            return (
                <InfoPopup
                    linkText={`${currentTimeZoneDisplay}, and will change.`}
                    amplitudeEventId="sanghaTimeZone"
                    modalProps={{
                        maxWidth: "sm",
                    }}
                >
                    <Typography variant="body1">
                        Your times are shown in {humanReadableTimeZone(timeZone)} time. However,
                        this sangha observes {humanReadableTimeZone(originalTimeZone)} time (
                        {originalTimeDescription()}).
                    </Typography>
                    <Typography variant="body1">
                        Due to daylight savings your local start time will periodically switch{" "}
                        {renderTimeDifferenceRange()}
                    </Typography>
                    <Typography variant="body1">
                        <strong>
                            But don't worry. We'll email you the exact time before each session.
                        </strong>
                    </Typography>
                </InfoPopup>
            );
        } else {
            return <>{currentTimeZoneDisplay}.</>;
        }
    };

    const getCycleDescription = (cycle: SanghaSessionCycle) => {
        switch (cycle) {
            case SanghaSessionCycle.twiceMonthly:
                return "twice a month";
            default:
                return cycle;
        }
    };

    const introText = `Your ${getSanghaGroupDescriptor(sangha)} ${
        isInvitation ? "will meet" : "meets"
    } ${getCycleDescription(sangha.cycle)} ${
        isInvitation && affectedByDSTChanges ? "roughly on" : "on"
    }`;

    return (
        <div data-testid="sanghaDescription">
            <Typography variant="body1" className={classes.descriptionText}>
                {introText}
            </Typography>
            <Typography variant="body1" className={classes.descriptionText}>
                <strong>
                    <SanghaDayAndTimeDescription sangha={sangha} userTimeZone={timeZone} />
                </strong>
            </Typography>
            <Typography variant="body2" color="textSecondary">
                {renderTimeZoneInfo()}
            </Typography>
        </div>
    );
};
