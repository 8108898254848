import { useMutation } from "@apollo/client";
import { Typography } from "@mui/material";
import { GRAPHQL_MUTATION_AUTHORIZE_OAUTH } from "app/queries";
import { GenericErrorPage } from "components/GenericErrorPage";
import PageWrapper from "components/PageWrapper";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useQueryParams } from "../../hooks/useQueryParams";
import { LoginForm } from "../auth/LoginForm";
import { selectAuthAndUserLoadCompleted, selectIsLoggedIn } from "../auth/auth";
import LoadingPage from "../pages/LoadingPage";
import { analyticsTrack } from "app/analytics/track";

export const AuthorizePage = (): JSX.Element => {
    const authComplete = useSelector(selectAuthAndUserLoadCompleted);
    const isLoggedIn = useSelector(selectIsLoggedIn);

    const query = useQueryParams();
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const [authorizeOAuthClient] = useMutation(GRAPHQL_MUTATION_AUTHORIZE_OAUTH);

    useEffect(() => {
        const authorize = async () => {
            const variables = {
                responseType: query.response_type,
                clientId: query.client_id,
                redirectUri: query.redirect_uri,
                scope: query.scope,
                state: query.state,
            };

            try {
                setLoading(true);
                const redirectUri = await authorizeOAuthClient({
                    variables,
                });
                window.location.href = redirectUri.data.authorizeOAuthClient;
            } catch (e: any) {
                analyticsTrack("oauth.authorize.error", { message: e.message, ...query, e });
                setError(e.message);
            }
        };

        if (isLoggedIn) {
            authorize();
        }
    }, [isLoggedIn, query]);

    if (error) {
        return <GenericErrorPage alternateInstruction={`Error: ${error}. If this continues, `} />;
    }

    if (!authComplete || loading) {
        return <LoadingPage />;
    }

    return (
        <PageWrapper maxWidth="sm">
            <Typography variant="h2">Welcome back!</Typography>

            <LoginForm />
        </PageWrapper>
    );
};
