import {
    Dialog as MaterialDialog,
    DialogActions,
    DialogContent,
    DialogProps as MaterialDialogProps,
    DialogTitle,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "@mui/material/IconButton";
import clsx from "clsx";
import _ from "lodash";
import * as React from "react";
import { useModalNavigation } from "../hooks/useModalNavigation";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
    dialog: {},
    dialogContainer: {
        padding: theme.spacing(8, 6, 6, 6),
        background: "none",

        "& > :first-child": {
            marginTop: 0,
        },
    },

    closeIcon: {
        position: "absolute",
        top: theme.spacing(2),
        right: theme.spacing(2),
        zIndex: 1,
        fontSize: theme.typography.pxToRem(35),
    },

    closeIconLarge: {
        fontSize: theme.typography.pxToRem(60),
    },
}));

export type CSDialogProps = {
    children: React.ReactNode | React.ReactNode[];
    closeButtonSize?: "large" | "small";
    onClose?: (ev: React.MouseEvent<any>) => void;
    open: boolean;
    classes?: Partial<ReturnType<typeof useStyles>>;
    transitionDuration?: number;
    dialogClasses?: MaterialDialogProps["classes"];
    title?: React.ReactNode;
    actions?: React.ReactNode;
    alignTitle?: "inherit" | "right" | "left" | "center" | "justify";
} & MaterialDialogProps;

export const CSDialog = (props: CSDialogProps) => {
    const classes = useStyles(props);
    const navigation = useModalNavigation();

    const {
        children,
        closeButtonSize = "large",
        onClose = () => navigation.goBack(),
        open,
        dialogClasses,
        title,
        actions,
        alignTitle,
        ...others
    } = props;

    const closeIconClasses = clsx(classes.closeIcon, {
        [classes.closeIconLarge]: closeButtonSize === "large",
    });

    return (
        <MaterialDialog
            className={classes.dialog}
            onClose={onClose}
            open={open}
            fullWidth
            maxWidth="md"
            data-testid="modal"
            transitionDuration={props.transitionDuration}
            classes={dialogClasses}
            {..._.omit(others, "classes")}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                data-testid="closeModalButton"
            >
                <span className={classNames("material-symbols-rounded", closeIconClasses)}>
                    close
                </span>
            </IconButton>

            {title && <DialogTitle align={alignTitle}>{title}</DialogTitle>}

            <DialogContent className={classes.dialogContainer}>
                {children}
            </DialogContent>

            {actions && <DialogActions>{actions}</DialogActions>}
        </MaterialDialog>
    );
};
