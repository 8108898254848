import MenuIcon from "@mui/icons-material/Menu";
import { IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import classNames from "classnames";
import clsx from "clsx";
import { selectIsLoggedIn } from "features/auth/auth";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useDetectScroll } from "hooks/useDetectScroll";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AccountMenu } from "./AccountMenu";
import { MenuLink } from "./MenuLink";
import { openMenu } from "./navigationSlice";
import { LinkTag, MenuLinkInfo, useNavigationLinks } from "./useNavigationLinks";
import { titleFont } from "app/theme";
import { LinkButton } from "./LinkButton";
import { routes } from "app/routes";
import { useLocation } from "react-router";
import { isUserInFreeTrialSession } from "features/signup/signupHelpers";

export const MENU_SHADOW_HEIGHT = 8;

const useStyles = makeStyles((theme) => ({
    root: {},
    list: {
        color: theme.palette.grey700,
        listStyle: "none",
        padding: 0,
        margin: 0,
        textAlign: "right",

        display: "flex",
        gap: theme.spacing(4),
        height: NAVBAR_HEIGHT - MENU_SHADOW_HEIGHT,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-end",
        position: "relative",

        [theme.breakpoints.down("md")]: {
            height: NAVBAR_HEIGHT_MOBILE,
        },
    },

    loggedInMenu: {
        // Partial menu underline. After scrolling, a full underline is styled in <TopBar />
        // "&:before": {
        //     content: "''",
        //     position: "absolute",
        //     zIndex: -1,
        //     left: 0,
        //     bottom: 0,
        //     top: 0,
        //     right: -200,
        //     boxShadow: `${MENU_SHADOW_HEIGHT}px 0 ${MENU_SHADOW_HEIGHT}px ${alpha(theme.palette.grey900, 0.1)}`,
        //     transition: "opacity 500ms ease",
        //     opacity: 1,
        // },
    },

    scrolledMenu: {
        "&:before": {
            opacity: 0,
        },
    },

    link: {
        ...theme.typography.h6,
        color: theme.palette.neutralWhite,
        textTransform: "uppercase",

        "&:hover": {
            color: theme.palette.accentGold,
        },
    },

    primaryLink: {
        ...theme.typography.body1,
        border: theme.border.default,
        padding: theme.spacing(1, 3),
        borderRadius: theme.borderRadius.default,
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.down("md")]: {
            display: "block",
        },
    },

    activeLink: {},

    webflowLink: {
        // custom font design to match the webflow design
        fontFamily: titleFont,
        color: theme.palette.primaryLeaves,
        fontSize: theme.typography.pxToRem(18),
        fontWeight: 600,
        marginLeft: -30,

        "&:hover": {
            color: theme.palette.grey700,
        },
    },
    menuIcon: {
        color: theme.palette.neutralWhite,
    },
    joinUsButton: {
        textTransform: "uppercase",
    },
}));

export const TopBarMenu = React.memo(function TopBarMenu(props: { className?: string }) {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const hasScrolled = useDetectScroll();

    const location = useLocation();
    const isInSignupFlow =
        location.pathname === routes.allInterestGroups() ||
        location.pathname.startsWith("/mindful-friend-groups/group/");

    const isFreeTrialSession = isUserInFreeTrialSession();

    const showMobileMenu = useBreakpointQuery("lg");

    const classes = useStyles();

    const links = useNavigationLinks();

    const navbarLinks = links.filter((x) => !x.tags.includes(LinkTag.ACCOUNT_LINKS));
    const accountMenuLinks = links.filter((x) => x.tags.includes(LinkTag.ACCOUNT_LINKS));

    const handleOpenMenu = (event: React.MouseEvent<any>) => {
        event.preventDefault();
        dispatch(openMenu());
    };

    const renderLink = (link: MenuLinkInfo) => {
        const className = classNames(classes.link, {
            [classes.primaryLink]: link.tags.includes(LinkTag.LOGIN),
        });
        return (
            <li key={link.name}>
                <MenuLink link={link} className={className} activeClassName={classes.activeLink} />
            </li>
        );
    };

    const renderMobileMenuIcon = () => (
        <IconButton onClick={handleOpenMenu}>
            <MenuIcon className={classes.menuIcon} />
        </IconButton>
    );

    const renderMenu = () => (
        <nav className={props.className}>
            <ul
                className={clsx(classes.list, {
                    [classes.loggedInMenu]: isLoggedIn,
                    [classes.scrolledMenu]: hasScrolled,
                })}
            >
                {navbarLinks.map(renderLink)}

                {!isLoggedIn && !isInSignupFlow && (
                    <LinkButton
                        to={routes.allInterestGroups()}
                        variant="primary"
                        size="small"
                        className={classes.joinUsButton}
                    >
                        {isFreeTrialSession ? "Start Free Trial" : "Join Us"}
                    </LinkButton>
                )}
                {accountMenuLinks.length > 0 ? <AccountMenu links={accountMenuLinks} /> : null}
            </ul>
        </nav>
    );

    return (
        <div className={classes.root} {...props}>
            {showMobileMenu ? renderMobileMenuIcon() : renderMenu()}
        </div>
    );
});
