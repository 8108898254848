import { getMentorshipGroupAddOnPrice } from "@app/shared/prices";
import { Membership, MembershipBillingPlan } from "@app/shared/types";
import { formatTimestamp } from "@app/shared/utils";
import { Chip, Paper, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { LIMITED_WIDTH } from "app/styles";
import { displayPrice } from "components/displayPrice";
import { DateTime } from "luxon";
import { DiscountExplanation } from "./DiscountExplanation";
import { useQuery } from "@apollo/client";
import { GRAPHQL_QUERY_STRIPE_CUSTOMER_BALANCE } from "app/queries";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(2),
        padding: theme.spacing(6),
        borderRadius: theme.borderRadius.default,
        transition: "background-color 300ms ease",
        backgroundColor: theme.palette.background.contrast,
        maxWidth: LIMITED_WIDTH,
    },
    donationHeart: {
        fontSize: "1.5rem",
    },

    priceLine: {
        marginTop: 0,
        marginBottom: theme.spacing(1),
    },

    name: {
        marginTop: 0,
    },
}));

export const BillingPlanExplanation = (props: {
    plan: MembershipBillingPlan;
    donationPrice?: number;
    firstBillDate?: DateTime;
    renewalDate?: DateTime;
    classes?: any;
    membership: Membership;
}): JSX.Element => {
    const classes = useStyles(props);
    const { plan, donationPrice, firstBillDate, renewalDate, membership } = props;

    const { data: balanceData } = useQuery(GRAPHQL_QUERY_STRIPE_CUSTOMER_BALANCE, {
        fetchPolicy: "network-only",
        variables: {
            stripeCustomerId: membership.stripeCustomerId,
        },
    });

    const stripeCustomerBalance = balanceData?.stripeCustomerBalance / 100 || 0;

    const { baseAmount, billedEvery, addon, cycle } = plan;

    const renderRenewalOrFirstBill = (date: DateTime, isFirst: boolean) => (
        <>
            <Typography variant="body2" color="textSecondary" sx={{ m: 0 }}>
                {isFirst ? "Your first bill will be " : "Your membership renews on "}
                {formatTimestamp(date.toMillis(), {
                    month: "long",
                    day: "numeric",
                })}
                .
            </Typography>
        </>
    );

    const totalPrice = donationPrice ? baseAmount + donationPrice : baseAmount;

    const renderPlanName = () => {
        return `You're contributing ${displayPrice(totalPrice)} every ${billedEvery}`;
    };

    const renderContributionBreakdown = (membershipAmount: number, addonAmount: number) => (
        <>
            <Typography className={classes.priceLine} variant="body1">
                <b>{displayPrice(membershipAmount)}</b>
                {" is your base membership."}
            </Typography>
            <Typography className={classes.priceLine} variant="body1">
                <b>{displayPrice(addonAmount)}</b>
                {" is for your mentorship group."}
            </Typography>

            {!!donationPrice && (
                <Typography className={classes.priceLine} variant="body1">
                    <b>{displayPrice(donationPrice)}</b>
                    {" is for your monthly donation."}
                </Typography>
            )}
        </>
    );

    const renderPrice = () => {
        if (addon) {
            const addonAmount = getMentorshipGroupAddOnPrice(plan.membershipType, cycle);
            const membershipAmount = baseAmount - addonAmount;
            return renderContributionBreakdown(membershipAmount, addonAmount);
        }

        return;
    };

    const discountApplied = !!membership.discount?.percentOff || !!membership.discount?.amountOff;

    return (
        <Paper elevation={0} className={classes.root} data-testid="billingPlanExplanation">
            {stripeCustomerBalance !== 0 && (
                <Tooltip
                    placement="top"
                    arrow
                    title="These are credits of past payments that will be applied to your future monthly payments. Your payment method will only be charged once this credit runs out."
                >
                    <Chip
                        size="small"
                        variant="darkGreen"
                        label={`Prepaid Balance: ${displayPrice(-stripeCustomerBalance)}`}
                        sx={{ mb: 1 }}
                    />
                </Tooltip>
            )}
            {discountApplied ? (
                <DiscountExplanation membership={membership} />
            ) : (
                <>
                    <Typography variant="h4" className={classes.name}>
                        {renderPlanName()}
                    </Typography>
                    {renderPrice()}
                </>
            )}
            {firstBillDate && renderRenewalOrFirstBill(firstBillDate, true)}
            {renewalDate && renderRenewalOrFirstBill(renewalDate, false)}
        </Paper>
    );
};

export default BillingPlanExplanation;
