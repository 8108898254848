import { useMutation } from "@apollo/client";
import { Session, SessionStarted } from "@app/shared/types";
import { ButtonProps, darken } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_POST_SESSION_ATTENDANCE,
    GRAPHQL_MUTATION_POST_SESSION_RSVP,
} from "app/queries";
import { selectIsAdmin } from "features/auth/auth";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import { useContext } from "react";
import { useSelector } from "react-redux";
import {
    ButtonWithConfirmationDialogs,
    ConfirmationDialogProps,
} from "./ButtonWithConfirmationDialogs";

const useStyles = makeStyles((theme) => ({}));

export interface ZoomButtonProps extends ButtonProps {
    isStartButton: boolean;
    isCardDropInSession?: boolean;
    isEventOngoing: boolean;
    event: Session;
    sessionStartedStatus: SessionStarted;
    confirmationDialogs?: ConfirmationDialogProps[];
    iconHeight?: number;
}

export const ZoomButton = (props: ZoomButtonProps) => {
    const user = useContext(LoggedInUserProfileContext);
    const {
        isStartButton,
        event,
        sessionStartedStatus,
        isCardDropInSession = false,
        isEventOngoing = false,
    } = props;

    const confirmationDialogs = props.confirmationDialogs || [];

    const [postSessionAttendance] = useMutation(GRAPHQL_MUTATION_POST_SESSION_ATTENDANCE, {
        variables: {
            sessionId: event.id,
        },
    });

    const [postSessionRsvp] = useMutation(GRAPHQL_MUTATION_POST_SESSION_RSVP, {
        variables: {
            sessionId: event.id,
            sendEmail: false,
        },
    });

    const isAdmin = useSelector(selectIsAdmin);

    const sessionInProgress = sessionStartedStatus === SessionStarted.IN_PROGRESS;
    const sessionStartingSoon = sessionStartedStatus === SessionStarted.ABOUT_TO_START;
    const sessionCancelled = event?.cancelled;
    const enableSessionButtons =
        isAdmin || (!sessionCancelled && (sessionInProgress || sessionStartingSoon));

    const isRegistrantLink = event.zoomInfo?.joinLink.includes("tk=");

    const analyticsMetadata = {
        session: event.id,
        isRegistrantLink,
        didRsvp: event.rsvpUsers?.some((x) => x.id === user.id),
        type: event.type,
        teacherName: event.teachers[0] ? event.teachers[0].name : "",
    };

    const startOrJoinSession = () => {
        if (!isStartButton && event.id !== "SilentMeditationHall") {
            // Log attendance
            postSessionAttendance().catch(() => {
                analyticsTrack("member.community.session.attendance.retry", analyticsMetadata);
                postSessionAttendance();
            });

            // log that this person is attending
            postSessionRsvp();
        }

        analyticsTrack(
            isStartButton ? "teacher.community.session.start" : "member.community.session.join",
            analyticsMetadata,
        );

        window.open(isStartButton ? event.zoomInfo?.startLink : event.zoomInfo?.joinLink, "_blank");
    };

    if (isCardDropInSession) {
        return (
            <div>
                <ButtonWithConfirmationDialogs
                    {...props}
                    variant={isStartButton || isEventOngoing ? "primaryInverse" : "tertiary"}
                    fullWidth={isEventOngoing ? true : false}
                    data-testid={isStartButton ? `startSessionButton` : `joinSessionButton`}
                    size="small"
                    disabled={!enableSessionButtons}
                    href={isStartButton ? event.zoomInfo?.startLink : event.zoomInfo?.joinLink}
                    onConfirm={() => startOrJoinSession()}
                    onOpenDialog={(dialogIndex) => {
                        analyticsTrack("member.community.session.confirmationDialog.open", {
                            ...analyticsMetadata,
                            dialogIndex,
                        });
                    }}
                    confirmationDialogs={confirmationDialogs}
                >
                    {isStartButton ? "Start session" : isEventOngoing ? "Join now" : "Attend"}
                </ButtonWithConfirmationDialogs>
            </div>
        );
    }

    return (
        <div>
            <ButtonWithConfirmationDialogs
                {...props}
                fullWidth
                variant="primaryInverse"
                data-testid={isStartButton ? `startSessionButton` : `joinSessionButton`}
                size="small"
                disabled={!enableSessionButtons}
                href={isStartButton ? event.zoomInfo?.startLink : event.zoomInfo?.joinLink}
                onConfirm={() => startOrJoinSession()}
                onOpenDialog={(dialogIndex) => {
                    analyticsTrack("member.community.session.confirmationDialog.open", {
                        ...analyticsMetadata,
                        dialogIndex,
                    });
                }}
                confirmationDialogs={confirmationDialogs}
            >
                {isStartButton ? "Start session" : "Join now"}
            </ButtonWithConfirmationDialogs>
        </div>
    );
};
