import { GRAPHQL_QUERY_TEACHERS } from "app/queries";
import { useQuery } from "@apollo/client";
import PageWrapper from "components/PageWrapper";
import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DocumentContext from "components/DocumentContext";
import { Teacher } from "@app/shared/types";
import { TeachersPageImageCard } from "./TeachersPageImageCard";
import { sortTeachersByLastName } from "./sortTeachers";
import { useDispatch } from "react-redux";
import { routes } from "app/routes";
import { push } from "connected-react-router";
import { useState } from "react";
import classNames from "classnames";
import { CircularProgressContainer } from "components/CircularProgressContainer";
import { GenericErrorMessage } from "components/GenericErrorMessage";

const useStyles = makeStyles((theme) => ({
    heroSection: {
        backgroundImage: `url("https://assets.cloudsangha.co/images/golden_fern-min.jpeg")`,
        color: theme.palette.neutralWhite,
        backgroundSize: "cover",
        position: "relative",
    },
    overlay: {
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        position: "absolute",
        inset: 0,
    },
    heroText: {
        position: "relative",
        margin: theme.spacing(6, 0),
    },
    headerBar: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        margin: theme.spacing(2, 0),
        color: theme.palette.grey700,
    },
    searchInputField: {
        "& .MuiOutlinedInput-root": {
            borderRadius: theme.borderRadius.large,
            marginBottom: 0,
        },
    },
    cardsContainer: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
        gap: theme.spacing(3),
    },
}));

export const TeachersPage = () => {
    const classes = useStyles();
    const { data, loading, error } = useQuery(GRAPHQL_QUERY_TEACHERS);
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState("");

    if (error) {
        return <GenericErrorMessage />;
    }

    const filteredTeachers: Teacher[] = (data?.teachers || []).filter((teacher: Teacher) =>
        teacher.name.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    const sortedTeachers = sortTeachersByLastName(filteredTeachers);

    return (
        <div>
            <DocumentContext title="Teachers" addBrandNameSuffix />
            <Box className={classes.heroSection}>
                <Box className={classes.overlay} />
                <PageWrapper removeHeader>
                    <Box className={classes.heroText}>
                        <Typography variant="h1" sx={{ mb: 0 }}>
                            Connect with Expert Mindfulness Teachers
                        </Typography>
                        <Typography variant="body1" sx={{ mb: 0 }}>
                            Weekly Sessions to Learn, Ask, and Meditate Together
                        </Typography>
                    </Box>
                </PageWrapper>
            </Box>
            <PageWrapper data-testid="memberTeachersPage" removeHeader>
                <Box className={classes.headerBar}>
                    <TextField
                        size="small"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className={classes.searchInputField}
                        placeholder="Search"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <span className="material-symbols-rounded">search</span>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="caption" sx={{ mb: 0 }}>
                        {sortedTeachers.length} teachers
                    </Typography>
                </Box>
                {loading ? (
                    <CircularProgressContainer />
                ) : (
                    <div className={classes.cardsContainer}>
                        {sortedTeachers.map((teacher: Teacher) => (
                            <TeachersPageImageCard
                                key={teacher.id}
                                teacher={teacher}
                                onClick={() => dispatch(push(routes.teacherPage(teacher.id)))}
                            />
                        ))}
                    </div>
                )}
            </PageWrapper>
        </div>
    );
};
