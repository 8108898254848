import { useQuery } from "@apollo/client";
import { MessageThread, MessageType } from "@app/shared/types";
import { Button, DialogContent, Link, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { GRAPHQL_QUERY_MESSAGE_THREAD, GRAPHQL_QUERY_SANGHA_DETAILS } from "app/queries";
import { NAVBAR_HEIGHT, NAVBAR_HEIGHT_MOBILE } from "app/styles";
import classNames from "classnames";
import { GenericErrorMessage } from "components/GenericErrorMessage";
import { LoadingBar } from "components/LoadingBar";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import LoadingPage from "features/pages/LoadingPage";
import { useBreakpointQuery } from "hooks/useBreakPointQuery";
import { useFetchDataAndKeepUpdated } from "hooks/useFetchDataAndKeepUpdated";
import { TwoColumnStickyLayout } from "layouts/TwoColumnStickyLayout";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { MessageSection } from "../messaging/MessageSection";
import { MemberSanghaDetails } from "./MemberSanghaDetails";
import { SanghaMembers } from "./SanghaMembers";
import { SanghaMembersHidden, SANGHA_MEMBERS_HIDDEN_HEIGHT } from "./SanghaMembersHidden";
import { CSDialog } from "components/CSDialog";
import { routes } from "app/routes";
import { BRAND_NAME } from "@app/shared/constants";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    sanghaDetailsSection: {
        textAlign: "left",

        [theme.breakpoints.up("md")]: {
            paddingTop: 0,
            paddingBottom: SANGHA_MEMBERS_HIDDEN_HEIGHT,
        },

        [theme.breakpoints.down("md")]: {
            position: "sticky",
            top: NAVBAR_HEIGHT_MOBILE - MENU_SHADOW_HEIGHT,
            alignItems: "stretch",
            backgroundColor: theme.palette.neutralWarm,
            zIndex: theme.zIndex.appBar + 1,
        },
    },

    noReflectionQuestion: {
        [theme.breakpoints.up("md")]: {
            paddingBottom: NAVBAR_HEIGHT,
        },
    },

    primaryColumn: {
        paddingBottom: 0,
    },

    button: {
        marginTop: theme.spacing(2),
    },
}));

export const MemberSangha = () => {
    const classes = useStyles();

    const { sanghaId } = useParams<{ sanghaId: string }>();

    const isMobile = useBreakpointQuery("md");

    const {
        data: sanghaData,
        loading: sanghaLoading,
        error: sanghaError,
        refetch: refetchSangha,
    } = useFetchDataAndKeepUpdated({
        query: GRAPHQL_QUERY_SANGHA_DETAILS,
        pollIntervalInMinutes: 30,
        options: { variables: { id: sanghaId } },
    });

    const sanghaName: string | undefined = sanghaData?.sangha?.name;
    const highlightMemberDisplay: boolean | undefined = sanghaData?.sangha?.highlightMemberDisplay;

    const [memberShadeOpen, setMemberShadeOpen] = useState(false);
    useEffect(() => {
        if (highlightMemberDisplay) {
            setMemberShadeOpen(true);
            analyticsTrack("member.sangha.memberShade.autoOpen", {
                sanghaName,
            });
        }
    }, [highlightMemberDisplay, sanghaName]);
    const openMemberShade = () => {
        setMemberShadeOpen(true);
        analyticsTrack("member.sangha.memberShade.open", { sanghaName });
    };
    const closeMemberShade = () => {
        setMemberShadeOpen(false);
        analyticsTrack("member.sangha.memberShade.close", { sanghaName });
    };

    const {
        data: messageThreadData,
        loading: messageThreadLoading,
        error: messageThreadError,
    } = useQuery(GRAPHQL_QUERY_MESSAGE_THREAD, {
        pollInterval: 1000 * 60 * 3, // refresh every 3 minutes
        fetchPolicy: "network-only",
        variables: {
            sanghaId,
        },
    });

    const messageThread = messageThreadData?.messageThread as MessageThread | undefined;

    const reflectionQuestionMessage = messageThread?.messages
        ? messageThread?.messages.find((x) => x.type === MessageType.REFLECTION_QUESTION)
        : undefined;

    useEffect(() => {
        analyticsTrack(`member.sangha.viewed`, { sanghaId });
    }, [sanghaId]);

    const hasPopupBeenDisplayed = (groupId: string) => {
        const storedPopupStatus = localStorage.getItem(`group_popup_${groupId}`);
        return storedPopupStatus !== null ? JSON.parse(storedPopupStatus) : false;
    };

    const markPopupAsDisplayed = (groupId: string) => {
        localStorage.setItem(`group_popup_${groupId}`, JSON.stringify(true));
    };

    const initialPopupStatus = !hasPopupBeenDisplayed(sanghaId);
    const [isPopupOpen, setPopupOpen] = useState(initialPopupStatus);

    useEffect(() => {
        if (!isPopupOpen && initialPopupStatus) {
            markPopupAsDisplayed(sanghaId);
        }
    }, [isPopupOpen, sanghaId, initialPopupStatus]);

    if (sanghaLoading || !sanghaData) {
        return <LoadingPage />;
    } else if (sanghaError) {
        return <GenericErrorMessage />;
    }

    const renderSanghaDetails = () => {
        if (isMobile && !reflectionQuestionMessage) {
            return null;
        }

        return (
            <MemberSanghaDetails
                sangha={sanghaData.sangha}
                reflectionQuestionMessage={reflectionQuestionMessage}
            />
        );
    };

    const renderSanghaMessages = () => {
        if (messageThreadLoading) {
            return <LoadingBar />;
        } else if (messageThreadError) {
            return <GenericErrorMessage />;
        }

        return (
            <>
                {isMobile && <Typography variant="h2">{sanghaName}</Typography>}

                <MessageSection
                    sangha={sanghaData.sangha}
                    activeThread={messageThreadData.messageThread}
                />
            </>
        );
    };

    return (
        <>
            <TwoColumnStickyLayout
                secondaryColumnFirst={true}
                secondaryColumn={renderSanghaDetails()}
                classes={{
                    secondaryColumn: classNames(
                        classes.sanghaDetailsSection,
                        classes.noReflectionQuestion,
                    ),
                    primaryColumn: classes.primaryColumn,
                }}
            >
                {renderSanghaMessages()}
            </TwoColumnStickyLayout>
            <SanghaMembersHidden onOpen={openMemberShade} />
            <SanghaMembers
                open={memberShadeOpen}
                onClose={closeMemberShade}
                onUpdateMember={() => refetchSangha()}
                sangha={sanghaData.sangha}
                hasReflectionQuestion={Boolean(reflectionQuestionMessage)}
            />
            <CSDialog open={isPopupOpen} onClose={() => setPopupOpen(false)}>
                <DialogContent>
                    <Typography variant="h2">Welcome to your group board</Typography>
                    <Typography>
                        This board lets you share reflections, connect with others, and access
                        resources your teacher may want to share.
                    </Typography>
                    <Typography>
                        <strong>Keep in mind</strong>
                    </Typography>
                    <Typography>
                        <ul>
                            <li>
                                The{" "}
                                <NavLink to={routes.communityGuidelines()}>
                                    community guidelines
                                </NavLink>{" "}
                                still apply.
                            </li>
                            <li>Your teacher will not regularly monitor or respond to posts.</li>
                            <li>
                                This isn't the place to contact {BRAND_NAME}. You can do that{" "}
                                <NavLink to={routes.contactUs()}>here</NavLink>.
                            </li>
                        </ul>
                    </Typography>
                    <Button
                        variant="primary"
                        className={classes.button}
                        onClick={() => setPopupOpen(false)}
                    >
                        I understand
                    </Button>
                </DialogContent>
            </CSDialog>
        </>
    );
};
