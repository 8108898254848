import { Session, UserProfile } from "@app/shared/types";
import { MenuItem, Typography, alpha } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import { externalImageSrc } from "app/images";
import { CSDialog } from "components/CSDialog";
import { MENU_SHADOW_HEIGHT } from "features/navigation/TopBarMenu";
import { useUserTimezone } from "hooks/useUserTimezone";
import _ from "lodash";
import { useState } from "react";
import UserProfileHighlight from "../profile/UserProfileHighlight";
import MemberDialog from "../sangha/MemberDialog";
import { RsvpPanel } from "./RsvpPanel";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import classNames from "classnames";
import { on } from "events";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { routes } from "app/routes";

const useStyles = makeStyles((theme) => ({
    root: {},
    modalTitle: {
        marginTop: theme.spacing(6),

        textAlign: "center",
    },
    button: {
        height: "67px",
        paddingLeft: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            marginLeft: 0,
            marginRight: 0,
        },
    },
    bottomBar: {
        position: "absolute",
        bottom: "0px",
        left: "0px",
        width: "100vw",
        boxShadow: `${MENU_SHADOW_HEIGHT}px 0 ${MENU_SHADOW_HEIGHT}px ${alpha(
            theme.palette.grey700,
            0.1,
        )}`,

        backgroundColor: theme.palette.neutralWarm,
    },
    bottomBarCol1: {
        display: "inline-block",
        width: "50%",
        textAlign: "right",
    },
    bottomBarCol2: {
        display: "inline-block",
        width: "50%",
    },
    fadeCarousel: {
        height: 50,
        width: 50,
        borderRadius: theme.borderRadius.circular,
        marginRight: theme.spacing(2),
    },
    card: {
        backgroundColor: theme.palette.background.contrast,
        color: theme.palette.grey700,
    },
    websiteLink: {
        color: theme.palette.grey700,
    },
    teacherLabel: {
        color: theme.palette.neutralWarm,
    },
    attendees: {
        marginBottom: 200,
    },
    menuItem: {
        marginBottom: 0,
    },
    menuItemIcon: {
        marginRight: theme.spacing(2),
    },
}));

export const WhosAttendingMenuItem = (props: {
    event: Session;
    rsvpUsers?: UserProfile[];
    renderEventTime: (event: Session) => {};
    classes?: Partial<ReturnType<typeof useStyles>>;
    onDialogClose?: () => void;
}) => {
    const { event, rsvpUsers, onDialogClose } = props;

    const dispatch = useDispatch();

    const classes = useStyles(props);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dialogMember, setDialogMember] = useState<UserProfile | null>(null);
    const pictures: string[] =
        rsvpUsers
            ?.filter((user) => user.picture)
            .map((user) => externalImageSrc(user.picture as string, 50)) ?? [];

    const timezone = useUserTimezone();
    return (
        <div className={classes.root}>
            {
                <MenuItem
                    onClick={() => {
                        setIsModalOpen(true);
                        analyticsTrack(`member.whosAttending.button.clicked`, {
                            eventId: event.id,
                        });
                    }}
                    className={classes.menuItem}
                    data-testid="whosAttendingButton"
                >
                    <span className={classNames("material-symbols-rounded", classes.menuItemIcon)}>
                        group
                    </span>{" "}
                    See participants ({rsvpUsers?.length ?? 0} attending)
                </MenuItem>
            }
            <CSDialog
                onClose={() => {
                    setIsModalOpen(false);
                    if (onDialogClose) {
                        onDialogClose();
                    }
                }}
                open={isModalOpen}
                transitionDuration={700}
                fullScreen={true}
            >
                <div data-testid="whosAttendingModal">
                    <div className={classes.attendees}>
                        <div className={classes.modalTitle}>
                            <Typography variant="h2">The people you’ll bond with</Typography>
                        </div>

                        <UserProfileHighlight
                            profile={{
                                ...event.teachers[0],
                                nickName: event.teachers[0].name,
                                bio: event.teachers[0].background,
                                isTeacher: true,
                            }}
                            onClick={() => {
                                dispatch(push(routes.teacherPage(event.teachers[0].id)));
                                analyticsTrack(`member.whosAttending.teacher_profile.opened`, {
                                    eventId: event.id,
                                    teacherName: event.teachers[0].name,
                                });
                            }}
                            classes={{
                                card: classes.card,
                                websiteLink: classes.websiteLink,
                                teacherLabel: classes.teacherLabel,
                            }}
                        />

                        {rsvpUsers?.map((profile: UserProfile, i: number) => (
                            <UserProfileHighlight
                                key={profile.id}
                                profile={profile}
                                onClick={() => {
                                    setDialogMember(profile);
                                    analyticsTrack(`member.whosAttending.member_profile.opened`, {
                                        eventId: event.id,
                                        clickedUser: profile.id,
                                    });
                                }}
                                classes={{
                                    card: classes.card,
                                    websiteLink: classes.websiteLink,
                                    teacherLabel: classes.teacherLabel,
                                }}
                            />
                        ))}
                    </div>
                    <RsvpPanel {..._.omit(props, "classes")} />

                    {dialogMember && (
                        <MemberDialog
                            member={dialogMember}
                            open={dialogMember !== null}
                            onClose={() => setDialogMember(null)}
                        />
                    )}
                </div>
            </CSDialog>
        </div>
    );
};
