import { getEventTitle } from "@app/shared/events";
import { DateTime } from "luxon";
import { Sangha, SanghaType, Session } from "@app/shared/types";
import { dateTimeFromString, getFormattedDateFromString } from "@app/shared/utils";

export const getSanghaGroupDescriptor = (sangha: Sangha): string => {
    switch (sangha.type) {
        case SanghaType.MentorshipGroup:
            return "Mentorship group";
        case SanghaType.InterestGroup:
            return "Mindful friends group";
        default:
            return "Sangha";
    }
};

export const getTitle = (session: Session): string => {
    return getEventTitle({ event: session, sanghaName: session.sangha?.name });
};

export const renderSessionDate = (session: Session, timezone: string) => {
    return dateTimeFromString(session.time, timezone).toLocaleString({
        weekday: "long",
        month: "long",
        day: "numeric",
    });
};

export const renderSessionTime = (session: Session, timezone: string) => {
    return `${getFormattedDateFromString(session.time, timezone)} - ${getFormattedDateFromString(
        session.endTime,
        timezone,
    )}`;
};

export const renderUserTimezone = (timezone: string) => {
    const dateTime = DateTime.now().setZone(timezone);
    const locale = navigator.language;
    const localDateTime = locale ? dateTime.setLocale(locale) : dateTime;
    return localDateTime.offsetNameShort;
};
