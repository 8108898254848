import { Avatar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { FadeCarousel } from "./FadeCarousel";

const useStyles = makeStyles((theme) => ({
    card: {
        backgroundColor: theme.palette.neutralCool,
        color: theme.palette.grey900,
        padding: theme.spacing(4, 5),
        borderRadius: theme.borderRadius.default,
        boxSizing: "border-box",
        [theme.breakpoints.down("md")]: {
            height: "100%",
        },
    },
    cancelledCard: {
        "& $contentWrapper": {
            opacity: 0.3,
        },
    },
    contentWrapper: {
        display: "flex",
        position: "relative",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: theme.spacing(2, 0),
        height: "100%",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "flex-start",
        },
    },
    content: {
        width: "100%",
        height: "100%",
    },
    actionBar: {
        textAlign: "right",
        [theme.breakpoints.down("md")]: {
            textAlign: "center",
        },
    },
    avatarRoot: {
        width: 100,
        height: 100,
        marginBottom: theme.spacing(2),
        borderRadius: theme.borderRadius.circular,
        cursor: "pointer",
        border: `2px solid ${theme.palette.accentGold}`,
        flexShrink: 0,
        [theme.breakpoints.down("lg")]: {
            marginBottom: theme.spacing(3),
        },
    },
    scheduleCard: {
        backgroundColor: theme.palette.primaryBanyan,
        color: theme.palette.neutralWhite,
    },
}));

export const LegacyEventCard = (props: {
    pictures: string[];
    pictureOnClick?: () => void;
    information: React.ReactNode;
    actionBar?: React.ReactNode;
    containerAttributes?: any;
    cancelled?: boolean;
    classes?: any;
    userIsPartOfGroup?: boolean;
}) => {
    const classes = useStyles(props);
    const {
        pictures,
        information,
        actionBar,
        containerAttributes,
        pictureOnClick,
        cancelled,
        userIsPartOfGroup,
    } = props;

    return (
        <div
            className={classNames(classes.card, {
                [classes.cancelledCard]: cancelled,
                [classes.scheduleCard]: userIsPartOfGroup,
            })}
            {...containerAttributes}
        >
            <div className={classes.contentWrapper}>
                {pictures.length > 1 ? (
                    <FadeCarousel
                        images={pictures}
                        interval={5000}
                        classes={{
                            container: classes.avatarRoot,
                        }}
                    />
                ) : (
                    <Avatar
                        data-testid="teacherAvatar"
                        src={pictures[0]}
                        variant="circular"
                        onClick={pictureOnClick}
                        classes={{
                            root: classes.avatarRoot,
                        }}
                        sx={{ width: 200, height: 200 }}
                    />
                )}

                <div className={classes.content}>{information}</div>
            </div>
            <div className={classes.actionBar}>{actionBar}</div>
        </div>
    );
};

export default LegacyEventCard;
