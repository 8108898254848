import { BRAND_NAME, CONFLICT_RESOLUTION_EMAIL } from "@app/shared/constants";
import { Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "features/navigation/NavLink";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            paddingBottom: theme.spacing(8),
        },
    },
    subList: {
        listStyleType: "lower-alpha",
    },
}));

export const Guidelines = ({ mainHeadingVariant = "h2" }: { mainHeadingVariant?: "h1" | "h2" }) => {
    const classes = useStyles();

    return (
        <div className={classes.contentContainer}>
            <Typography variant="h1">Community Guidelines</Typography>

            <Typography variant="h3">Banyan is a safe space</Typography>
            <Typography variant="body1">
                We aim to create a safe, neutral space where everyone feels included and all
                emotions are welcome. Our goal is to foster mindfulness, understanding, and
                compassion for all perspectives.
            </Typography>

            <Typography variant="h3">I agree to&hellip;</Typography>
            <Typography variant="body1" component="div">
                <ol>
                    <li>
                        Honor everyone’s unique journey, regardless of race, color, nationality,
                        sex, gender, age, religion, ability, medical conditions, military status, or
                        marital status.
                    </li>
                    <li>Support others with kindness and respect.</li>
                    <li>
                        Share from my own experience, honestly and vulnerably, using “I” statements.
                        Speak from the heart.
                    </li>
                    <li>
                        Take responsibility for my own process when I share. Allow others to be a
                        loving witness, but not expect them to hold me. If I need to pause, take a
                        breath or return to the main room.
                    </li>
                    <li>Avoid giving unsolicited advice or trying to 'fix' another. </li>
                    <li>
                        Hold what others share in strict confidence.
                        <ol className={classes.subList}>
                            <li>
                                Avoid using AI transcription tools such as{" "}
                                <NavLink to={"https://www.read.ai/"} target="_blank">
                                    read.ai
                                </NavLink>
                                ,{" "}
                                <NavLink to={"https://www.otter.ai/"} target="_blank">
                                    otter.ai
                                </NavLink>
                                , and Zoom IQ.
                            </li>
                        </ol>
                    </li>
                    <li>
                        Encourage full participation by all present. Move back if I speak often,
                        move up if I speak less.
                    </li>
                    <li>
                        Set aside roles I might hold like coach, teacher, or therapist.
                        <ol className={classes.subList}>
                            <li>
                                Avoid promoting of products or services or asking for personal
                                favors outside the group context.
                            </li>
                        </ol>
                    </li>
                    <li>
                        To be fully present and to support the presence of others:
                        <ol className={classes.subList}>
                            <li>Arrive early to settle in for an on-time start.</li>
                            <li>Mute my microphone when not speaking.</li>
                            <li>Use a device with a big screen that allows me to see everyone.</li>
                            <li>
                                Turn off all other devices (cellphones, tablets, etc). Give my full
                                presence to the session and refrain from multitasking.{" "}
                            </li>
                            <li>
                                Keep my video on to enhance connection, and briefly switch it off if
                                I need to move.
                            </li>
                            <li>"Hide Self View" in Zoom for more natural interaction.</li>
                        </ol>
                    </li>
                    <li>
                        In breakout rooms:
                        <ol className={classes.subList}>
                            <li>Nominate a timekeeper.</li>
                            <li>Know that it's okay not to share. </li>
                            <li>
                                Chat casually once everyone has had a chance to respond to the
                                prompt.{" "}
                            </li>
                        </ol>
                    </li>
                    <li>Leave the breakout room or the session anytime I feel uncomfortable.</li>
                    <li>
                        Avoid:
                        <ol className={classes.subList}>
                            <li>
                                Unpacking or processing active trauma. Examples of actively
                                processing trauma: ranting or airing grievances, incapable of
                                speaking. Before sharing, please reflect: what is the purpose of my
                                sharing? Can I offer a learning or reflection on this? Will others
                                be able to benefit from what I share?{" "}
                            </li>
                            <li>Coping with unregulated mental illness.</li>
                            <li>Talking about causing intentional harm to myself or others.</li>
                            <li>
                                Avoid sharing political opinions and going into political debates.
                                Instead, explore how mindfulness practice can help you hold
                                difficulties that may arise due to politics.
                            </li>
                        </ol>
                    </li>
                </ol>
            </Typography>

            <Typography variant="h3">What to do when impacted by another member’s share</Typography>
            <Typography variant="body1">
                <ol>
                    <li>Pause. Take a moment to breathe and care for yourself.</li>
                    <li>
                        Our community includes a wide variety of backgrounds and perspectives.
                        Consider that there may have been no intent to cause harm, or awareness that
                        harm was caused.
                    </li>
                    <li>
                        Without judgement, blame or reactivity, let your pod know that something
                        shared impacted you using “I” statements. This can lead to healing and a
                        deeper understanding for all. Wait until the next session to share, if the
                        current session feels too charged.
                    </li>
                    <li>
                        If you are outside your window of tolerance of what feels safe and
                        manageable, please take steps to gently care for yourself.
                    </li>
                </ol>
            </Typography>

            <Typography variant="h3">
                What to do when concerned you have impacted another member{" "}
            </Typography>

            <Typography variant="body1">
                <ol>
                    <li>
                        Pause, relax & open; take a moment to breathe and extend some self
                        compassion.
                    </li>
                    <li>
                        We can have best intentions when sharing, but it can still have a negative
                        impact. Try to understand and acknowledge the impact. **Focusing only** on
                        good intent can be more destructive than the initial interaction.
                    </li>
                    <li>
                        Acknowledge that you are concerned you may have caused unintentional harm.
                        Example: “If what I shared caused harm, please forgive me. This was not my
                        intention, and I understand there may still have been impact.”
                    </li>
                </ol>
            </Typography>

            <Typography variant="body1">
                <strong>
                    Report any concerns or conflicts to {BRAND_NAME} (
                    <NavLink href={`mailto:${CONFLICT_RESOLUTION_EMAIL}`}>
                        {CONFLICT_RESOLUTION_EMAIL}
                    </NavLink>
                    ).
                </strong>
            </Typography>

            <Typography variant="h3">
                I understand that {BRAND_NAME} reserves the right to...
            </Typography>
            <Typography variant="body1" component="div">
                <ul>
                    <li>
                        Redirect members to seek more suitable support from a therapist or other
                        professional, if their participation here could be detrimental.
                    </li>
                    <li>Remove members who repeatedly breach these guidelines.</li>
                    <li>Remove members posts that are divisive or exclusionary.</li>
                </ul>
            </Typography>
        </div>
    );
};

export default Guidelines;
