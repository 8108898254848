import { Teacher } from "@app/shared/types";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";

interface TeachersPageImageCardProps {
    teacher: Teacher;
    onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
    card: {
        position: "relative",
        borderRadius: theme.borderRadius.default,
        boxShadow: theme.shadow.default,
        flexGrow: 1,
        backgroundColor: theme.palette.neutralWarm,
    },
    teacherImage: {
        width: "100%",
        height: 270,
        borderRadius: theme.borderRadius.default,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        objectFit: "cover",
        objectPosition: "center",
    },
    cardContent: {
        padding: theme.spacing(1, 3, 2, 3),
    },
    infoTextBox: {
        display: "flex",
        alignItems: "center",
    },
    infoIcons: {
        fontSize: theme.typography.pxToRem(18),
        marginRight: theme.spacing(1),
    },
}));

export const TeachersPageImageCard = (props: TeachersPageImageCardProps) => {
    const { teacher, onClick } = props;

    const classes = useStyles();
    return (
        <Box className={classes.card} data-testid="teachersPageImageCard">
            <img
                className={classes.teacherImage}
                src={teacher.picture || undefined}
                alt={teacher.name}
            />
            <Box className={classes.cardContent}>
                <Typography variant="h3">{teacher.name}</Typography>
                <Typography variant="body1" className={classes.infoTextBox} sx={{ mb: 0 }}>
                    <span className={classNames("material-symbols-rounded", classes.infoIcons)}>
                        location_on
                    </span>
                    {teacher.location}
                </Typography>
                <Typography variant="body1" className={classes.infoTextBox} sx={{ mb: 1 }}>
                    <span className={classNames("material-symbols-rounded", classes.infoIcons)}>
                        play_arrow
                    </span>
                    {teacher.yearsTeaching} years teaching
                </Typography>
                <Button variant="tertiary" onClick={onClick} data-testid="learnMoreButton">
                    Learn more
                </Button>
            </Box>
        </Box>
    );
};
