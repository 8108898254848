import { useMutation } from "@apollo/client";
import { Session } from "@app/shared/types";
import { MenuItem, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { analyticsTrack } from "app/analytics/track";
import {
    GRAPHQL_MUTATION_POST_CANCEL_SESSION_RSVP,
    GRAPHQL_MUTATION_POST_SESSION_RSVP,
    GRAPHQL_QUERY_EVENT_RSVP_USERS,
} from "app/queries";
import { CSDialog } from "components/CSDialog";
import { LoggedInUserProfileContext } from "features/auth/RequireAuthentication";
import calendarIcon from "images/icons/calendar-icon.svg";
import checkIcon from "images/icons/check.svg";
import _ from "lodash";
import { useContext, useState } from "react";
import { UserProfile } from "react-redux-firebase";
import { getEventAnalyticsData } from "./AddToCalendarMenuItem";
import { AddToSingleCalendarButton, CalendarType } from "./AddToSingleCalendarButton";

const useStyles = makeStyles((theme) => ({
    root: {},
    icon: {
        marginRight: theme.spacing(2),
    },
    rsvpModal: {
        minHeight: 300,
        textAlign: "center",
    },
    cancelRsvp: {
        background: theme.palette.primaryLeaves,
    },
}));

const calendarTypes: CalendarType[] = [
    CalendarType.apple,
    CalendarType.google,
    CalendarType.outlookcom,
    CalendarType.yahoo,
];

export const RsvpMenuItem = (props: {
    event: Session;
    classes?: Partial<ReturnType<typeof useStyles>>;
    rsvpUsers?: UserProfile[];
}) => {
    const { event } = props;
    const classes = useStyles(props);
    const [rvspModalOpen, setRsvpModalOpen] = useState(false);
    const user = useContext(LoggedInUserProfileContext);

    const [callPostSessionRsvp] = useMutation(GRAPHQL_MUTATION_POST_SESSION_RSVP, {
        refetchQueries: [{ query: GRAPHQL_QUERY_EVENT_RSVP_USERS, variables: { id: event.id } }],
    });

    const postSessionRsvp = (sendEmail = false) => {
        callPostSessionRsvp({
            variables: {
                sessionId: event.id,
                sendEmail,
            },
        });
    };

    const [callPostCancelSessionRsvp] = useMutation(GRAPHQL_MUTATION_POST_CANCEL_SESSION_RSVP, {
        refetchQueries: [{ query: GRAPHQL_QUERY_EVENT_RSVP_USERS, variables: { id: event.id } }],
    });

    const postCancelSessionRsvp = () => {
        callPostCancelSessionRsvp({
            variables: {
                sessionId: event.id,
            },
        });
    };

    const userIsAttending = () => {
        const rsvpForUser = _.find(props.rsvpUsers, {
            id: user.id,
        });

        return rsvpForUser;
    };

    const logEvent = (eventName: string, additionalData: Object = {}) => {
        analyticsTrack(eventName, {
            ...additionalData,
            ...getEventAnalyticsData(event),
        });
    };

    return (
        <div className={classes.root}>
            {userIsAttending() ? (
                <>
                    <MenuItem
                        className={classes.cancelRsvp}
                        data-testid="rsvpCancelButton"
                        onClick={() => {
                            postCancelSessionRsvp();
                            logEvent("member.community.rsvp.cancel");
                        }}
                    >
                        <img height="35" className={classes.icon} src={checkIcon} alt="" />
                        You plan to attend
                    </MenuItem>
                </>
            ) : (
                <MenuItem
                    data-testid="rsvpButton"
                    onClick={() => {
                        setRsvpModalOpen(true);
                        postSessionRsvp();
                        logEvent("member.community.rsvp.click");
                    }}
                >
                    <img height="35" className={classes.icon} src={calendarIcon} alt="" />
                    Attend
                </MenuItem>
            )}

            <CSDialog onClose={() => setRsvpModalOpen(false)} open={rvspModalOpen}>
                <div data-testid="rsvpModal" className={classes.rsvpModal}>
                    <Typography variant="h4">Add to your calendar</Typography>
                    {calendarTypes.map((type) => (
                        <div key={type}>
                            <AddToSingleCalendarButton
                                calendarType={type}
                                event={event}
                                onClick={() => {
                                    logEvent("member.community.rsvp.add", {
                                        rsvpType: "calendar",
                                    });
                                    setRsvpModalOpen(false);
                                }}
                            />
                        </div>
                    ))}
                </div>
            </CSDialog>
        </div>
    );
};
