import makeStyles from "@mui/styles/makeStyles";
import { SignupProgressBar } from "./SignupProgressBar";
import PageWrapper from "components/PageWrapper";
import { Button, Chip, Typography } from "@mui/material";
import classNames from "classnames";
import { theme } from "app/theme";
import { MembershipBillingCycle } from "@app/shared/types";

const useStyles = makeStyles((theme) => ({
    baseCard: {
        borderRadius: theme.borderRadius.default,
        padding: theme.spacing(7, 4, 4, 4),
    },
    annualCard: {
        backgroundColor: theme.palette.primaryLeaves,
        color: theme.palette.neutralWhite,
        border: `4px solid ${theme.palette.accentEarthy}`,
        position: "relative",
    },
    monthlyCard: {
        border: `1px solid ${theme.palette.grey400}`,
        color: theme.palette.primaryBanyan,
    },
    cardContainer: {
        display: "flex",
        justifyContent: "center",
        gap: theme.spacing(4),
        [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            gap: theme.spacing(2),
        },
    },
    bestValueHeader: {
        position: "absolute",
        top: 0,
        left: 0,
        textAlign: "center",
        width: "100%",
        backgroundColor: theme.palette.accentEarthy,
        borderTopLeftRadius: theme.borderRadius.default,
        borderTopRightRadius: theme.borderRadius.default,
        padding: theme.spacing(0.5),
        marginTop: -4,
    },
}));

interface ChooseMonthlyOrAnnualPageProps {
    onSelect: (billingCycle: MembershipBillingCycle) => void;
}

export const ChooseMonthlyOrAnnualPage = (props: ChooseMonthlyOrAnnualPageProps) => {
    const { onSelect } = props;
    const classes = useStyles();

    return (
        <>
            <SignupProgressBar currentStep={1} />
            <PageWrapper>
                <Typography variant="h1">Choose your plan for after your free trial</Typography>

                <div className={classes.cardContainer}>
                    <div className={classNames(classes.baseCard, classes.annualCard)}>
                        <Typography variant="overline" className={classes.bestValueHeader}>
                            <strong>BEST VALUE</strong>
                        </Typography>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                paddingBottom: theme.spacing(1),
                            }}
                        >
                            <Typography variant="h3" sx={{ mb: 0 }}>
                                Annual
                            </Typography>
                            <Chip variant="green" size="small" label="Save 30%" />
                        </div>
                        <Typography variant="body1">
                            <ul
                                style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(6),
                                }}
                            >
                                <li>Unlimited mindful groups</li>
                                <li>+75 monthly talks</li>
                                <li>+100 live meditations</li>
                            </ul>
                        </Typography>
                        <Typography
                            variant="h4"
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "end",
                                mb: 0,
                            }}
                        >
                            <div>
                                <span style={{ fontSize: 36 }}>$34</span>/mo{" "}
                            </div>
                            <span
                                style={{
                                    fontSize: 12,
                                    marginLeft: theme.spacing(1),
                                    marginBottom: theme.spacing(1),
                                }}
                            >
                                BILLED $408
                            </span>
                        </Typography>
                        <Button
                            variant="primary"
                            fullWidth
                            sx={{ mt: 1 }}
                            onClick={() => onSelect(MembershipBillingCycle.year)}
                            data-testid="confirmAnnualPlanButton"
                        >
                            Select plan
                        </Button>
                    </div>

                    <div className={classNames(classes.baseCard, classes.monthlyCard)}>
                        <Typography variant="h3" sx={{ mb: 0, pb: theme.spacing(1.5) }}>
                            Monthly
                        </Typography>
                        <Typography variant="body1">
                            <ul
                                style={{
                                    paddingLeft: theme.spacing(2),
                                    paddingRight: theme.spacing(6),
                                }}
                            >
                                <li>Unlimited mindful groups</li>
                                <li>+75 monthly talks</li>
                                <li>+100 live meditations</li>
                            </ul>
                        </Typography>
                        <Typography variant="h4">
                            <span style={{ fontSize: 36 }}>$49</span>
                            <span style={{ fontSize: 26 }}>/mo</span>
                        </Typography>
                        <Button
                            variant="primary"
                            fullWidth
                            sx={{ mt: 1 }}
                            onClick={() => onSelect(MembershipBillingCycle.month)}
                            data-testid="confirmMonthlyPlanButton"
                        >
                            Select plan
                        </Button>
                    </div>
                </div>
            </PageWrapper>
        </>
    );
};
